#screenshots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.autoSlideButton {
  background: #010101;
  border: none;
  border-radius: 7px;
  color: white;
  font-size: 5rem;
  width: 5rem;
  height: 5rem;
  transition: opacity 0.3s;
  opacity: 0.7;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10rem;
  left: 5%;
  cursor: pointer;
}
.autoSlideButton i {
  font-size: 2.4rem;
}
.RedColor {
  color: #c03232;
}
.whiteBackground {
  background: white;
  box-shadow: inset -2px 2px 18px grey, inset -1px 2px 5px black,
    0px 2px 4px darkgrey;
}
.autoCount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoCount::after {
  font: 800 40px system-ui;
  content: counter(count);
  animation: counter 5s linear infinite;
  counter-reset: count 0;
}

@keyframes counter {
  0% {
    counter-increment: count 5;
  }
  20% {
    counter-increment: count 4;
  }
  40% {
    counter-increment: count 3;
  }
  60% {
    counter-increment: count 2;
  }
  80% {
    counter-increment: count 1;
  }
  100% {
    counter-increment: count 0;
  }
}

.slides {
  display: grid;
}
.slides > .slide {
  grid-area: 1/-1;
}
.slides > button {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  background: #010101;
  border: none;
  border-radius: 7px;
  color: white;
  position: absolute;
  font-size: 5rem;
  width: 5rem;
  height: 5rem;
  top: 30%;
  transition: opacity 0.3s;
  opacity: 0.7;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slides > button:hover {
  opacity: 1;
}
.slides > button:focus {
  outline: none;
}
.slides > button:first-child {
  left: 5%;
  top: 7em;
  color: white;
}
.slides > button:last-child {
  right: 5%;
  top: 7em;
  color: white;
}

.slideContent {
  width: 20vw;
  height: 45vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;
  display: grid;
  align-content: center;
  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(100% * var(--offset)))
    rotateY(calc(-45deg * var(--dir)));
}

.slideContentInner {
  transform-style: preserve-3d;
  transform: translateZ(2rem);
  transition: opacity 0.3s linear;
  /* text-shadow: 0 0.1rem 1rem #000; */
  opacity: 0;
}

.slideContent:hover .slideContentInner {
  filter: opacity(0.1);
}
.slideTitle,
.slideSubtitle {
  color: #7c4fe0 !important;
  font-weight: bold;
  /* text-shadow: 0 0.1rem 1rem #000 !important; */
}
.slideSubtitle {
  font-size: 1.4rem !important;
}
.slideDescription {
  color: black !important;
  font-weight: bold;
  font-size: 72% !important;
}
.slideContentInner .slideSubtitle,
.slideContentInner .slideTitle {
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 0.2ch;
  text-transform: uppercase;
  margin: 0;
}
.slideContentInner .slideSubtitle::before {
  content: "— ";
}
.slideContentInner .slideDescription {
  margin: 0;
  font-size: 40%;
  letter-spacing: 0.2ch;
}

/* .slideBackground {
  position: fixed;
  top: 0;
  left: -10%;
  right: -10%;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out;
  pointer-events: none;
  transform: translateX(calc(10% * var(--dir)));
} */

.slide[data-active] {
  z-index: 2;
  pointer-events: auto;
}
.slide[data-active] .slideBackground {
  opacity: 0.2;
  transform: none;
}
.slide[data-active] .slideContentInner {
  opacity: 1;
  background: rgba(255, 255, 255, 0.9);
  padding: 12px;
  width: 90%;
  border-radius: 4px;
  margin-left: 4.6%;
  box-shadow: 0 2px 6px darkgrey;
}
.slide[data-active] .slideContent {
  --x: calc(var(--px) - 0.5);
  --y: calc(var(--py) - 0.5);
  opacity: 1;
  transform: perspective(1000px);
}
.slide[data-active] .slideContent:hover {
  transition: none;
  transform: perspective(1000px) rotateY(calc(var(--x) * 45deg))
    rotateX(calc(var(--y) * -45deg));
}
