@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");

.vc_column_container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) {
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.section-title {
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 48%;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 992px) {
  .section-title h3 {
    font-size: 2.25em;
  }
}
.section-title p {
  font-size: 18px;
  color: #999;
  margin: 0;
  font-weight: 600;
  line-height: 1.75;
}
.vc_row.vc_row-flex {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.vc_row.vc_row-flex > .vc_column_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.vc_row.vc_row-flex > .vc_column_container > .vc_column-inner {
  box-flex: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1;
}
.features-item {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.features-item > li {
  list-style: none;
  width: auto;
  height: auto;
  padding: 4px;
}
.feature-box {
  margin-bottom: 10px;
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  transition: all 0.7s ease-in-out both;
}
.feature-box .box-icon {
  margin-right: 20px;
  transform: translate(0px, 29px);
}
.feature-box .box-icon .icon {
  font-size: 40px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
}
.feature-box .box-text > h4 {
  margin-bottom: 15px;
  transition: all 0.3s;
}

.wpb_single_image.vc_align_center {
  text-align: center;
}
.wpb_single_image .vc_figure {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  max-width: 100%;
}
.wpb_single_image .vc_single_image-wrapper {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
.pictureContnt {
  width: 72%;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.features-presentation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  border-bottom: 1px solid gray;
}
.container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-features:hover .section-title .headCaracteristic .desktopLogos {
  animation: fadeInUp 0.7s ease-in-out both;
}

.col-lg-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.feature-box .box-text > h4 {
  margin-bottom: 15px;
  transition: all 0.3s;
}

.align-self-md-start {
  text-align: left;
}
@media (min-width: 768px) {
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
}

.features-phone #phone,
.features-phone1 #phone {
  width: 279px;
}
.features-phone #phone .top-bar,
.features-phone1 #phone .top-bar {
  width: 246px;
}
.features-phone #phone #screen,
.features-phone1 #phone #screen {
  width: 246px;
}
.features-phone #phone .headerPhone,
.features-phone1 #phone .headerPhone {
  width: 246px;
}
.features-phone #phone .headerPhone .battery,
.features-phone1 #phone .headerPhone .battery {
  left: 238px;
}
.features-phone #phone .headerPhone .signal,
.features-phone1 #phone .headerPhone .signal {
  left: 175px;
}
.features-phone #phone .headerPhone .timePhone,
.features-phone1 #phone .headerPhone .timePhone {
  position: absolute;
  top: 63px;
  color: white;
  left: 57px;
  font-size: 10px;
}
.features-phone #phone .icons-phone .icon-home,
.features-phone1 #phone .icons-phone .icon-home {
  /* left: 148px; */
  transform: scale(0.8) rotate(56deg);
}
.features-phone #phone .icons-phone .icon-recent,
.features-phone1 #phone .icons-phone .icon-recent {
  transform: scale(0.8);
}
.features-phone #phone .icons-phone .icon-back,
.features-phone1 #phone .icons-phone .icon-back {
  /* left: 237px; */
  transform: scale(0.8);
}
/* .features-phone #phone #screen {
  background-image: url(../../assets/count-light.jpg);
  background-size: 100% 100%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
} */
.features-phone1 #phone #screen {
  background-image: url(../../assets/prfile/1-light-opt.jpg);
  background-size: 99% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
  transition: all 0.7s ease-in-out both;
}

.features2b #phone #screen {
  background-image: url(../../assets/prfile/screen2-opt.jpg);
  background-size: 95% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
  transition: all 0.7s ease-in-out both;
}
.shareOption #phone #screen {
  background-image: url(../../assets/prfile/3-opt.jpg);
  background-size: 95% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
  transition: all 0.7s ease-in-out both;
}
.inviteOption #phone #screen {
  background-image: url(../../assets/prfile/4-opt.jpg);
  background-size: 95% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
  transition: all 0.7s ease-in-out both;
}
.items2row {
  box-shadow: 0 2px 7px grey;
  padding: 14px;
  border-radius: 17px;
  background: whitesmoke;
  width: 95%;
  margin-left: 1rem;
  cursor: pointer;
  border: 2px solid #7c4fe0;
}

.items2row:last-child {
  margin-bottom: 2rem;
}

.figure {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9rem;
}
.features-phone1 #phone {
  transform: rotateY(49deg) rotatex(0deg) translate(-13em, -20rem) scale(0.8);
  position: absolute;
  z-index: 99;
  filter: drop-shadow(-10px 0px 1px black);
  border-radius: 51px;
}
.features-phone #phone {
  /* border: solid 3px lightgray;
  border-left: 2px solid white;
  border-bottom: 1px solid gray;
  border-right: 4px solid black; */
  box-shadow: 2px 0px 2px white, 2px 2px 2px grey, -3px 4px 14px black;
  filter: drop-shadow(4px 0px 1px black);
}

.features-phone1 #phone {
  box-shadow: inset 0 2px 7px 0 rgb(0 0 0), -3px 0px 4px white,
    -4px 6px 2px grey;
}
.headCaracteristic {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.arrowDraw {
  transform: rotate(-76deg) rotateY(6deg) rotateX(-16deg) translate(-14px, 0);
}
.arrowText {
  display: inline-block;
  font-family: "Handlee", cursive;
  transform: translate(0px, -12px);
  font-size: 1.5rem;
  margin-right: 1.1rem;
  color: black;
}
.testIt-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 330px;
  transform: translate(-7rem, 0);
}
.back {
  transform: rotate(82deg) rotateY(21deg) rotateX(-166deg) translate(-35px, 0);
}
.icon-home {
  cursor: pointer;
}
.realFrame-container #phone .icons-phone .icon-home {
  padding: 2px;
}
