section#screenshots {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-bottom: 6rem;
}

.carousel-wrapper {
  overflow: hidden;
  width: 90%;
}
.carousel-wrapper * {
  box-sizing: border-box;
}
.carousel {
  transform-style: preserve-3d;
}
.carousel__photo {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  margin: auto;
  padding: 1rem 4rem;
  z-index: 100;
  transition: transform 0.5s, opacity 0.5s, z-index 0.5s;
}
.carousel__photo.initial,
.carousel__photo.active {
  opacity: 1;
  position: relative;
  z-index: 900;
}
.carousel__photo.prev,
.carousel__photo.next {
  z-index: 800;
}
.carousel__photo.prev {
  transform: translateX(-100%); /* Move 'prev' item to the left */
}
.carousel__photo.next {
  transform: translateX(100%); /* Move 'next' item to the right */
}
.carousel__button--prev,
.carousel__button--next {
  position: absolute;
  top: 50%;
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1001; /* Sit on top of everything */
  border: 1px solid black;
}
.carousel__button--prev {
  left: 0;
}
.carousel__button--next {
  right: 0;
}
.carousel__button--prev::after,
.carousel__button--next::after {
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 54%;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: translate(-50%, -50%) rotate(135deg);
}
.carousel__button--next::after {
  left: 47%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
