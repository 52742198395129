/* @property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
} */
.vc_custom_1622205278038 {
  background-image: url(../../assets/counters-parallax-opt.jpg) !important;
}
.container-counter {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter-content h5 {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin: 0 0 20px 0;
  text-align: center;
}
/* Sounds Number */
/* .number-count {
  transition: --num 5s;
  counter-set: num var(--num);
  font: 800 40px system-ui;
}
.number-count::after {
  content: counter(num);
}
#counters:hover
  .container-counter
  .vc_column_container
  .vc_column-inner
  .wpb_wrapper
  .counter
  h5 {
  filter: opacity(1);
}
#counters:hover
  .container-counter
  .vc_column_container
  .vc_column-inner
  .wpb_wrapper
  .counter
  h5
  .number-count {
  --num: 42;
} */
/* Levels number */
/* .number-count-niveaux {
  transition: --num 5s;
  counter-set: num var(--num);
  font: 800 40px system-ui;
}
.number-count-niveaux::after {
  content: counter(num);
}
#counters:hover
  .container-counter
  .vc_column_container
  .vc_column-inner
  .wpb_wrapper
  .counter
  h5
  .number-count-niveaux {
  --num: 4;
} */
/* Users number */
/* .number-count-users {
  transition: --num 5s;
  counter-set: num var(--num);
  font: 800 40px system-ui;
}
.number-count-users::after {
  content: counter(num);
}
#counters:hover
  .container-counter
  .vc_column_container
  .vc_column-inner
  .wpb_wrapper
  .counter
  h5
  .number-count-users {
  --num: 1153;
} */

/* Infos pages */
/* .number-count-health {
  transition: --num 5s;
  counter-set: num var(--num);
  font: 800 40px system-ui;
}
.number-count-health::after {
  content: counter(num);
}
#counters:hover
  .container-counter
  .vc_column_container
  .vc_column-inner
  .wpb_wrapper
  .counter
  h5
  .number-count-health {
  --num: 21;
} */

.count-grid .icon i::before {
  font-size: 2.9rem;
}
.count-grid .icon {
  margin-bottom: 2rem;
}
