@media (max-width: 991px) {
  .banner {
    height: auto;
  }
}
.banner {
  position: relative;
  height: 800px;
  padding-top: 130px;
  padding-bottom: 100px;
  overflow: hidden;
}
.banner:before {
  background: linear-gradient(-47deg, #7c4fe0 0%, #4528dc 100%);
}
.banner:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.banner .container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
section .container {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  height: 100%;
  /* padding-bottom: 100px; */
}
/* section#contact {
  margin-top: -13em;
} */
/* @media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
} */
.button-store.wow {
  display: flex;
}
.vc_row:after,
.vc_row:before {
  content: " ";
  display: table;
}
.vc_column_container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .vc_col-sm-12 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .vc_col-sm-1,
  .vc_col-sm-10,
  .vc_col-sm-11,
  .vc_col-sm-12,
  .vc_col-sm-2,
  .vc_col-sm-3,
  .vc_col-sm-4,
  .vc_col-sm-5,
  .vc_col-sm-6,
  .vc_col-sm-7,
  .vc_col-sm-8,
  .vc_col-sm-9 {
    float: left;
  }
}
.vc_col-lg-1,
.vc_col-lg-10,
.vc_col-lg-11,
.vc_col-lg-12,
.vc_col-lg-2,
.vc_col-lg-3,
.vc_col-lg-4,
.vc_col-lg-5,
.vc_col-lg-6,
.vc_col-lg-7,
.vc_col-lg-8,
.vc_col-lg-9,
.vc_col-md-1,
.vc_col-md-10,
.vc_col-md-11,
.vc_col-md-12,
.vc_col-md-2,
.vc_col-md-3,
.vc_col-md-4,
.vc_col-md-5,
.vc_col-md-6,
.vc_col-md-7,
.vc_col-md-8,
.vc_col-md-9,
.vc_col-sm-1,
.vc_col-sm-10,
.vc_col-sm-11,
.vc_col-sm-12,
.vc_col-sm-2,
.vc_col-sm-3,
.vc_col-sm-4,
.vc_col-sm-5,
.vc_col-sm-6,
.vc_col-sm-7,
.vc_col-sm-8,
.vc_col-sm-9,
.vc_col-xs-1,
.vc_col-xs-10,
.vc_col-xs-11,
.vc_col-xs-12,
.vc_col-xs-2,
.vc_col-xs-3,
.vc_col-xs-4,
.vc_col-xs-5,
.vc_col-xs-6,
.vc_col-xs-7,
.vc_col-xs-8,
.vc_col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.vc_column_container {
  width: 100%;
}
.vc_column_container > .vc_column-inner {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.vc_column-inner::after,
.vc_column-inner::before {
  content: " ";
  display: table;
}
.vc_row.vc_row-flex {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.vc_row {
  /* margin-left: -15px; */
  margin-right: -15px;
  /* width: 90%; */
}
.vc_row.vc_row-o-content-middle:not(.vc_row-o-equal-height)
  > .vc_column_container {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.vc_row.vc_row-flex > .vc_column_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.wpb_row.vc_row-flex .vc_column_container {
  display: flex;
}
.vc_column_container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) {
  .vc_col-md-6 {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .vc_col-md-1,
  .vc_col-md-10,
  .vc_col-md-11,
  .vc_col-md-12,
  .vc_col-md-2,
  .vc_col-md-3,
  .vc_col-md-4,
  .vc_col-md-5,
  .vc_col-md-6,
  .vc_col-md-7,
  .vc_col-md-8,
  .vc_col-md-9 {
    float: left;
  }
}
/* @media (min-width: 768px) {
  .vc_col-sm-12 {
    width: 100%;
  }
} */
@media (min-width: 768px) {
  .vc_col-sm-1,
  .vc_col-sm-10,
  .vc_col-sm-11,
  .vc_col-sm-12,
  .vc_col-sm-2,
  .vc_col-sm-3,
  .vc_col-sm-4,
  .vc_col-sm-5,
  .vc_col-sm-6,
  .vc_col-sm-7,
  .vc_col-sm-8,
  .vc_col-sm-9 {
    float: left;
  }
}
.vc_row.vc_row-o-content-middle:not(.vc_row-o-equal-height)
  > .vc_column_container {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.vc_row.vc_row-flex > .vc_column_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 992px) {
  .vc_col-md-6 {
    width: 50%;
  }
}
.vc_column_container > .vc_column-inner {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 992px) {
  .banner .banner-text {
    text-align: left;
  }
}
.banner-text {
  text-align: center;
}
.banner-text h1 {
  font-size: 60px;
  color: #fff;
  margin-bottom: 30px;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.banner-text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
}
.button-store .custom-btn {
  min-width: 180px;
  padding: 10px 25px;
  border: 1px solid #fff;
  font-weight: 500;
  border-radius: 3px;
}
.button-store .custom-btn i {
  font-size: 27px;
  margin-right: 10px;
  color: #fff;
  transition: all 0.3s;
}
.fab,
.far {
  font-weight: 400;
}

.fab {
  font-family: "Font Awesome 5 Brands";
}
.button-store .custom-btn {
  min-width: 180px;
  padding: 10px 25px;
  border: 1px solid #fff;
  font-weight: 500;
  border-radius: 3px;
}
@media (min-width: 576px) {
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.fa-google-play:before {
  content: "\f3ab";
}
.button-store .custom-btn p {
  display: block;
  font-size: 17px;
  line-height: 1.4;
  color: #fff;
  font-weight: 700;
  margin: 0;
  transition: all 0.3s;
}
.button-store .custom-btn p em {
  display: block;
  clear: both;
  font-style: normal;
  font-size: 12px;
  margin: 0;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s;
}
.fa-apple:before {
  content: "\f179";
}
.vc_column-inner::after {
  clear: both;
}
.vc_row.vc_row-o-content-middle:not(.vc_row-o-equal-height)
  > .vc_column_container {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 1200px) {
  .vc_col-lg-6 {
    width: 50%;
  }
}
.banner-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72%;
  transform: translate(0, 45px);
  perspective: 6000px;
}
.overviewBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72%;

  perspective: 4784px;
}
.bounce-effect {
  position: relative;
  -webkit-animation: bounce-hero 3s infinite;
  -moz-animation: bounce-hero 3s infinite;
  animation: bounce-hero 3s infinite;
}
@keyframes bounce-hero {
  0% {
    top: 0px;
  }
  50% {
    top: 25px;
  }
  100% {
    top: 0px;
  }
}
.wave-effect {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 150px;
  overflow: hidden;
}
.waves-shape {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.waves-shape.shape-one {
  z-index: 15;
  opacity: 0.5;
}
.wave-one {
  background-image: url(../../assets//banner/wave-1.png);
  background-size: 50% 100px;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waves-shape.shape-two {
  z-index: 10;
  opacity: 0.75;
}
.wave-anim .wave-two {
  -webkit-animation: move-wave 10s linear infinite;
  -moz-animation: move-wave 10s linear infinite;
  animation: move-wave 10s linear infinite;
}
.wave-two {
  background-image: url(../../assets/banner/wave-2.png);
  background-size: 50% 120px;
}
@keyframes move-wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.waves-shape.shape-three {
  z-index: 5;
}
.wave-anim .wave-three {
  -webkit-animation: move-wave 15s linear infinite;
  -moz-animation: move-wave 15s linear infinite;
  animation: move-wave 15s linear infinite;
}
.wave-three {
  background-image: url(../../assets/banner/wave-3.png);
  background-size: 50% 100px;
}
.button-store.wow.fadeInUp {
  display: flex;
  align-items: center;
  width: 100%;
}
.button-store .custom-btn:first-child {
  margin-right: 2rem !important;
}
.firstview {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}
.firstviewContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstviewleft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 37%;
}
.wow,
.section-title,
.section-title-overview-first,
.section-title-overview-first h3,
.titleSecondSection,
.overviewParag-second,
.section-title h3,
.show,
.collapsed {
  font-family: "Permanent Marker";
  font-style: normal;
  font-weight: 400;
}
.subHome {
  width: 72%;
}
.desktopLogos {
  height: auto;
  display: grid;
  grid-template-columns: repeat(5, 11%);
  justify-content: space-evenly;
  padding-top: 1em;
  padding-bottom: 2em;
  width: 73%;
}
.iconDesktop {
  font-size: 2rem;
  color: #7c4fe0;
}
/* .desktopLogosContainer {
  width: 100%;
} */

.appleProd {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconDesktop.fab.fa-apple {
  position: absolute;
  top: 0;
  transform: scale(0.5) translate(0vw, -0.5vw);
}

/* [data-inviewport="fadeInUp"] {
  transition: 1s;
  visibility: hidden;
  overflow: hidden;
} */

.wow:hover .phoneFrame-container {
  transform: rotateY(50deg) scale(0.8) rotatex(14deg) translate(-19rem, -1rem);
  transition: all 0.7s ease-in-out;
}
