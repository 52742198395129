.overview-first-section,
.overview-second-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.overview-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.section-title-overview-first {
  padding-bottom: 40px;
  width: 96%;
  text-align: left;
}
.section-title-overview {
  padding-bottom: 40px;
  width: 82%;
  text-align: left;
}
.overviewParag {
  font-size: 18px;
  color: #999;
  margin: 0;
  width: 59%;
}
/* .section-title-overview h3 {
  line-height: 0.4rem;
} */
.contentSecondSection .overview-box {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
  padding-left: 1.7em;
  padding-right: 1.7em;
  margin-bottom: 25px;
  border-radius: 6px;
  width: 88%;
  transition: all 0.3s;
  transform: translateX(0);
  align-items: center;
  justify-content: flex-start;
}
.second {
  text-align: left;
  padding: 17px;
  width: 70%;
}
.second h6 {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}
.wpb_wrapper.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42%;
  /* padding-left: 2em; */
}
.wpb_wrapper.vc_figure {
  width: 39%;
}
.wrapperPhone1 {
  transform: translate(0, 7rem);
}
.overviewParag {
  font-size: 18px;
  color: #999;
  margin: 0;
  width: 91%;
}
.overviewParag-first {
  width: 64%;
}
.overviewParag-second {
  font-size: 18px;
  color: #999;
  margin: 0;
  width: 69%;
  text-align: left;
}
.titleSecondSection {
  word-break: break-word;
  text-align: left;
  width: 84%;
  line-height: 1.1em !important;
}
.wpb_wrapper.secondOverviewlist {
  width: 80%;
  text-align: left;
}
.overview-list {
  width: 96%;
}
.fa-check:before {
  content: "\f00c";
}
/* .overview-list ul > li {
  margin-bottom: 1.6em !important;
} */
.btn:hover,
#zoom-in:hover,
#zoom-out:hover,
.pagination li a:hover,
.pagination li a.active,
.page-links .post-page-numbers:hover {
  background-color: #5f36bb;
}
.btn:hover,
.btn:focus,
.btn:active,
.checkout-button:hover,
.checkout-button:focus,
.checkout-button:active,
.wp-block-button__link:hover,
.wp-block-button__link:focus,
.wp-block-button__link:active,
.wp-block-search__button:hover,
.wp-block-search__button:focus,
.wp-block-search__button:active,
.btn:hover,
.btn:focus,
.btn:active,
.search-submit:hover,
.search-submit:focus,
.search-submit:active {
  color: #fff;
  box-shadow: 0 10px 25px rgb(0 0 0 / 10%) !important;
}
.learnButtonContainer {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  width: 70%;
}
.learn.morebutton.btn {
  border: navajowhite;
  border-radius: 9px;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 18px;
}
.contentSecondSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
  width: 100%;
}
.phoneFrame-overview {
  transform: rotateY(50deg) scale(0.7) rotatex(14deg) translate(0, -1rem);
  position: absolute;
  z-index: 8;
  filter: drop-shadow(-13px 9px 2px black);
  border-radius: 51px;
}

.phoneFrame-overview1 {
  transform: rotateY(-51deg) scale(0.7) rotatex(8deg) translate(17rem, -9rem);
  z-index: 99;
  position: absolute;
  filter: drop-shadow(15px 12px 5px black);
  border-radius: 51px;
  transition: all 0.7s ease-in-out;
}
.phoneFrame-overview1:hover {
  transform: rotateY(-51deg) scale(1) rotatex(1deg) translate(17rem, -9rem);
  z-index: 99;
  position: absolute;
  filter: drop-shadow(15px 12px 5px black);
  border-radius: 51px;
  transition: all 0.7s ease-in-out;
}
.phoneFrame-overview #phone #screen,
.phoneFrame-overview1 #phone #screen {
  background-size: 100% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
}
.phoneFrame-overview #phone .defaultSound {
  background-image: url(../../assets/overview2-opt.jpg);
}
.phoneFrame-overview1 #phone .defaultSound {
  background-image: url(../../assets/overview1-opt.jpg);
}

.phoneFrame-overview #phone .intermediateSound {
  background-image: url(../../assets/soundsByLevel/intermediate-dark-opt.jpg);
}
.phoneFrame-overview1 #phone .intermediateSound {
  background-image: url(../../assets/soundsByLevel/intermediate-light-opt.jpg);
}

.phoneFrame-overview #phone .otherSounds {
  background-image: url(../../assets/soundsByLevel/other-light-opt.jpg);
}
.phoneFrame-overview1 #phone .otherSounds {
  background-image: url(../../assets/soundsByLevel/other-dark-opt.jpg);
}

.vc_single_image-wrapper .phoneFrame-overview1-bottom #phone {
  border: solid 3px lightgray;
  border-left: 4px solid white;
  border-bottom: 4px solid gray;
  border-right: 2px solid white;
}
.features-phone #phone {
  border: solid 3px lightgray;
  border-left: 1px solid darkgrey;
  border-bottom: 4px solid white;
  border-right: 2px solid lightgray;
}

.vc_single_image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 2000px;
}
.phoneFrame-overview-bottom {
  width: 50%;
  transform: rotate3d(0, 67, 0, -38deg);
}

.phoneFrame-overview1-bottom {
  width: 50%;
  transform: rotate3d(0, 67, 0, 38deg);
}

.phoneFrame-overview1-bottom #phone #screen,
.phoneFrame-overview-bottom #phone #screen {
  width: 88%;
}
.phoneFrame-overview1-bottom #phone,
.phoneFrame-overview-bottom #phone {
  width: 100%;
}
.phoneFrame-overview1-bottom #phone .top-bar,
.phoneFrame-overview-bottom #phone .top-bar {
  width: 92%;
}
.phoneFrame-overview1-bottom #phone .headerPhone .timePhone,
.phoneFrame-overview-bottom #phone .headerPhone .timePhone {
  font-size: 11px;
  left: 46px;
  top: 4rem;
}
.phoneFrame-overview1-bottom #phone .headerPhone .signal,
.phoneFrame-overview-bottom #phone .headerPhone .signal {
  left: 181px;
}
.phoneFrame-overview1-bottom #phone .headerPhone .battery,
.phoneFrame-overview-bottom #phone .headerPhone .battery {
  left: 248px;
}

.phoneFrame-overview1-bottom #phone {
  filter: drop-shadow(-11px 0px 1px black);
  box-shadow: inset 0 6px 25px 0px rgb(0 0 0), -9px 0px 35px white,
    4px 0px 9px grey;
}
.phoneFrame-overview-bottom #phone {
  filter: drop-shadow(12px -1px 0px black);
  box-shadow: inset 0 14px 52px 0 rgb(0 0 0), 6px 1px 29px white,
    1px 0px 2px gray;
  border-right: 4px solid white;
}
.phoneFrame-overview-bottom #phone .icons-phone .icon-back,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-back,
.phoneFrame-overview-bottom #phone .icons-phone .icon-home,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-home,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-recent,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-recent {
  transform: scale(0.8);
}
.phoneFrame-overview-bottom #phone .icons-phone .icon-home,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-home {
  transform: rotate(54deg) scale(0.8);
}
.phoneFrame-overview-bottom #phone .icons-phone .icon-recent,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-recent {
  transform: scale(0.8);
}
/* .phoneFrame-overview-bottom #phone .icons-phone .icon-back,
.phoneFrame-overview1-bottom #phone .icons-phone .icon-back {
  transform: translate(-100px, -4px);
} */
.phoneFrame-overview-bottom #phone #screen {
  background-image: url(../../assets/counter/time.jpg);
  background-size: 100% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
}
.phoneFrame-overview1-bottom #phone #screen {
  background-image: url(../../assets/counter/time-chrono.jpg);
  background-size: 100% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
}
.contentSecondSection .overview-box .icon {
  background: #7c4fe0;
  width: 59px;
  height: 59px;
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentSecondSection .overview-box:hover,
.contentSecondSection .overview-box--active {
  background: #7c4fe0;
  color: white;
  transform: translateX(-0.4em);
  transition: all 0.3s;
}
.overview-box--active .second h6 {
  color: white !important;
}
.contentSecondSection .overview-box:not(:hover) .second h6 {
  color: black;
}

.contentSecondSection .overview-box .icon i {
  color: #fff;
  font-size: 1.2rem !important;
}
.secondOverviewlist li {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.secondOverviewlist li i {
  transform: translate(0, 18px);
  margin-right: 0.6rem;
  color: #7c4fe0;
}
.overview-box {
  cursor: pointer;
}
.overviewBanner .phoneFrame-overview1 #phone {
  border: solid 3px #d4cece;
  border-left: 4px solid white;
  border-bottom: 4px solid gray;
  border-right: 3px solid #f2eded;
}
.lastFigure {
  margin-top: 4rem;
}
