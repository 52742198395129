.container-services,
.container-overview,
.contentSecondSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#services {
  padding-bottom: 4rem;
}
.services-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.service-single {
  margin-right: 17px;
}
.service-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.service-single h5 {
  margin: 25px 0 15px;
  text-align: center;
  transition: all 0.3s;
  font-size: 1.25rem;
}
.service-single {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
  padding: 50px;
  border-radius: 6px;
  text-align: center;
  transition: all 0.3s;
  transform: translateY(0);
  max-width: 23em;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.service-single:hover {
  background: #7c4fe0;
  color: white;
  transform: translateY(-0.4em);
  transition: all 0.3s;
}
.service-single .icon {
  background: #7c4fe0;
  width: 100px;
  height: 100px;
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-single .icon i,
.contentSecondSection .overview-box .icon i {
  color: #fff;
  font-size: 2.6rem;
}
.service-single:hover .icon,
.contentSecondSection .overview-box:hover .icon,
.contentSecondSection .overview-box--active .icon {
  background: #fff;
  transform: translateY(-0.4em);
  transition: all 0.3s;
}
.service-single:hover .icon i,
.contentSecondSection .overview-box:hover .icon i,
.contentSecondSection .overview-box--active .icon i {
  color: #7c4fe0;
}
.service-single:hover h5,
.contentSecondSection:hover h6,
.overview-box--active h6 {
  color: white;
}
