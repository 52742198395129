.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* padding: 40px; */
}
.cardsContainer .card {
  position: relative;
  width: 320px;
  height: 450px;
  background: #287bff;
  margin: 30px;
  border-radius: 20px;
  border-bottom-left-radius: 160px;
  border-bottom-right-radius: 160px;
  box-shadow: 0 15px 0 rgb(255, 255, 255),
    inset 0 -15px 0 rgba(255, 255, 255, 0.25), 0 25px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top: none;
}
.cardsContainer .card::before {
  content: "";
  position: absolute;
  top: -140px;
  left: -40%;
  width: 100%;
  height: 120%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2));
  transform: rotate(35deg);
  pointer-events: none;
  filter: blur(5px);
}
.cardsContainer .card:nth-child(1) {
  background: linear-gradient(to bottom, #ff2ae0, #645bf6);
}
.cardsContainer .card:nth-child(2) {
  background: linear-gradient(to bottom, #ffec61, #f321d7);
}
.cardsContainer .card:nth-child(3) {
  background: linear-gradient(to bottom, #24ff72, #9a4eff);
}
.cardsContainer .card .icon {
  position: relative;
  width: 140px;
  height: 120px;
  background: #fff;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: 0 10px 0 rgb(42 38 38 / 10%),
    inset 0 -15px 0 rgb(179 171 171 / 25%), 0 17px 0 rgb(0 0 0 / 15%);
  z-index: 1000;
}
.cardsContainer .card .icon::before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-top-left-radius: 50px;
  box-shadow: -15px -15px 0 15px #fff;
}
.cardsContainer .card .icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-top-right-radius: 50px;
  box-shadow: 15px -15px 0 15px #fff;
}
.cardsContainer .card .icon i {
  position: relative;
  font-size: 5em;
  z-index: 10000;
}

.cardsContainer .card:hover .icon i {
  color: var(--i);
  transition: 0.5s;
}
.contentCard {
  padding-top: 27px;
  color: white;
  padding: 30px;
}
.contentCard h5 {
  color: white !important;
  padding-bottom: 15px;
  font-size: 1.75em;
}
