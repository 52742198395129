.page_404 {
  padding: 0;
  background: #fff;
  font-family: "Arvo", serif;
}
.page_404 .container {
  position: relative;
  z-index: 2;
  padding-top: 24px;
  height: 100%;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url("../../assets/errorMobile.gif");
  height: 475px;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  transform: translate(0, -51px);
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #7c4fe0;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
