.container-pricing {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 0 !important;
}
.price-table {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
  padding: 30px 0;
  border-radius: 6px;
  text-align: center;
  transform: translateY(0);
  transition: all 0.3s;
  min-width: 330px !important;
}
.vc_row > .wpb_column:first-child .price-table {
  border-radius: 6px 0 0 6px;
}
.pricesTable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67%;
  margin-bottom: 4rem;
}
.price-table .icon {
  display: inline-block;
  font-size: 54px;
  line-height: 54px;
  margin-bottom: 20px;
}
.price-table.plan-popular {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 35px 0 rgb(0 0 0 / 15%);
  margin: -22px -30px 0;
  padding: 45px 0;
  border-bottom: 2px solid #7c4fe0;
}
.price-table.plan-popular .icon {
  margin-bottom: 28px;
}
.card-ribbon {
  position: absolute;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: 114px;
  height: 114px;
  z-index: 99;
}
.card-ribbon:before {
  top: 0;
  right: 0;
}
.card-ribbon:before,
.card-ribbon:after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #000;
  border-top-color: transparent;
  border-left-color: transparent;
}
.card-ribbon span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 9px 0;
  background-color: #ea830e;
  box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
  color: #fff;
  text-align: center;
  left: -37px;
  top: 21px;
  transform: rotate(-45deg);
}
.card-ribbon:after {
  bottom: 0;
  left: 0;
}

.price-table.plan-popular .plan-price {
  margin-bottom: 25px;
}
.price-table .plan-price {
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}
.price-table .plan-type {
  margin-bottom: 10px;
  text-align: center;
  transition: all 0.3s;
}
.price-table:hover {
  transform: translateY(-1rem);
  box-shadow: 0 2px 36px lightgray;
  transition: all 0.7s ease-in-out both;
}
.btn {
  border-radius: 7px;
}
.wpb_single_image .vc_single_image-wrapper {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
.mobile-phone img {
  width: 100%;
}
.wpb_single_image .vc_figure {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  width: 100%;
}
.wpb_single_image.vc_align_center {
  text-align: center;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phoneFrame-pricing-bottom {
  perspective: 800px;
  /* height: 47vh; */
}
.phoneFrame-pricing-bottom #phone {
  width: 75%;
  /* transform: rotateX(52deg) rotate(-90deg) translate(21rem, 0rem);
  filter: drop-shadow(-11px 0px 0px black);
  box-shadow: inset 0 38px 49px 0 rgb(0 0 0), -5px 6px 37px white,
    0px 0px 2px grey;
  transition: all 0.7s ease-in-out; */
  box-shadow: -7px 10px 10px #333;
  transform: rotateX(60deg) rotateY(3deg) rotateZ(-25deg);
  transform-origin: 50% 50%;
  transition: all 1s;
  margin-top: -2rem;
  border-right: 3px solid lightgrey !important;
}
.phoneFrame-pricing-bottom #phone:hover {
  width: 75%;
  /* transform: rotateX(37deg) rotate(-90deg);
  filter: drop-shadow(-11 px 0 px 0 px black);
  box-shadow: inset 0 38px 49px 0 rgb(0 0 0), -5px 6px 37px white,
    0px 0px 2px grey;
  transition: all 0.7s ease-in-out; */
  transform: rotate3d(0, 0, 0, 0deg);
  box-shadow: -100px 100px 20px #fff;
}
.phoneFrame-pricing-bottom #phone #screen {
  width: 90%;
}
.phoneFrame-pricing-bottom #phone .top-bar {
  width: 90%;
}
.phoneFrame-pricing-bottom #phone .headerPhone .timePhone {
  font-size: 10px;
  top: 4rem;
  left: 3rem;
}
.phoneFrame-pricing-bottom #phone .headerPhone .signal {
  left: 184px;
}
.phoneFrame-pricing-bottom #phone .headerPhone .battery {
  left: 256px;
}

.phoneFrame-pricing-bottom #phone .icons-phone .icon-back,
.phoneFrame-pricing-bottom #phone .icons-phone .icon-home,
.phoneFrame-pricing-bottom #phone .icons-phone .icon-recent {
  transform: scale(0.8);
}
.phoneFrame-pricing-bottom #phone .icons-phone .icon-home {
  transform: rotate(56deg);
}
/* .phoneFrame-pricing-bottom #phone .icons-phone .icon-back {
  transform: translate(-6rem, 0rem);
} */

.phoneFrame-pricing-bottom #phone #screen {
  background-image: url(../../assets/last-opt.jpg);
  background-size: 100% 106%;
  background-position-y: top;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
}
.price-table ul {
  margin: 0 10px 0 -26px;
}
.priceAnimateContainer .fadeInUp figure {
  height: 23vh !important;
}
.priceAnimateContainer {
  margin-top: -2rem;

  /* height: 26vh; */
}
