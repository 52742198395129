.andy,
.head,
.antennas,
.left-antenna,
.right-antenna,
.eyes,
.left-eye,
.right-eye,
.body,
.arms,
.left-arm,
.right-arm,
.legs,
.left-leg,
.right-leg {
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0;
}

.android-logo {
  width: 18%;
  height: 14.6em;
  margin: auto;
  position: absolute;
  top: -4rem;
  left: 22%;
  transition: all 0.7s ease-in-out;
}

.andy {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.andy,
.antennas,
.eyes,
.arms,
.legs {
  list-style-type: none;
}

.head,
.left-antenna,
.right-antenna,
.body,
.left-arm,
.right-arm,
.left-leg,
.right-leg {
  background-color: #a4c739;
}

.head,
.body {
  display: block;
  margin: auto;
}

.left-antenna,
.right-antenna,
.left-eye,
.right-eye,
.left-arm,
.right-arm,
.left-leg,
.right-leg {
  display: inline-block;
}

/* HEAD */

.head {
  position: relative;
  width: 9.2em;
  height: 4.2em;
  border-radius: 50% / 100% 100% 0 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.antennas,
.eyes {
  position: absolute;
  width: 9.2em;
}

/* ANTENNAS */

.antennas {
  top: -2.14em;
  left: 0.1em;
}

.left-antenna,
.right-antenna {
  width: 0.3em;
  height: 1.9em;
  border-radius: 0.24em;
}

.left-antenna {
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.right-antenna {
  margin-left: 3.48em;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

/* EYES */

.eyes {
  top: 1.78em;
  transform: translate(-2rem, 0);
}

.left-eye,
.right-eye {
  width: 0.76em;
  height: 0.76em;
  background-color: #fff;
  border-radius: 50%;
  /*-webkit-box-shadow: 0em 0em 0.5em 0.5em rgba(255,255,255,0.2);
  box-shadow: 0em 0em 0.5em 0.5em rgba(255,255,255,0.2);*/
}

.right-eye {
  margin-left: 3.36em;
}

/* BODY */

.body {
  position: relative;
  top: 0.36em;
  width: 9.2em;
  height: 7.68em;
  border-radius: 0em 0em 1.08em 1.08em;
}

.arms,
.legs {
  position: absolute;
}

/* ARMS */

.arms {
  width: 17em;
  top: -0.18em;
  left: -2.36em;
}

.left-arm,
.right-arm {
  width: 2em;
  height: 6.24em;
  border-radius: 1em;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.left-arm {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: -3rem;
}

.right-arm {
  margin-left: 8.65em;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg) translate(10 rem, 0 px);
}

/* LEGS */

.legs {
  top: 1.8em;
  left: 1.8em;
}

.left-leg,
.right-leg {
  width: 2.06em;
  height: 3.5em;
  margin-top: 5.66em;
  border-radius: 0em 0em 1.04em 1.04em;
}

.right-leg {
  margin-left: 1.28em;
}

/* ANIMATION CLASSES */

.anim-head {
  -webkit-animation: headAnimation 30s ease-out 3s infinite alternate;
  animation: headAnimation 30s ease-out 3s infinite alternate;
}

.anim-l-ant {
  -webkit-animation: leftAntennaAnimation 10s ease-out 3s infinite alternate;
  animation: leftAntennaAnimation 10s ease-out 3s infinite alternate;
}

.anim-r-ant {
  -webkit-animation: rightAntennaAnimation 10s ease-out 3s infinite alternate;
  animation: rightAntennaAnimation 10s ease-out 3s infinite alternate;
}

.anim-eyes {
  -webkit-animation: eyesAnimation 50s ease-in-out 0s infinite alternate;
  animation: eyesAnimation 50s ease-in-out 0s infinite alternate;
}

.anim-l-eye,
.anim-r-eye {
  -webkit-animation: blinkAnimation 7s linear 0s infinite alternate;
  animation: blinkAnimation 7s linear 0s infinite alternate;
}

.anim-arms {
  -webkit-animation: armsAnimation 30s linear 7s infinite;
  animation: armsAnimation 30s linear 7s infinite;
}

.anim-l-arm {
  -webkit-animation: leftArmAnimation 30s ease 7s infinite;
  animation: leftArmAnimation 30s ease 7s infinite;
}

.anim-r-arm {
  -webkit-animation: rightArmAnimation 30s ease 7s infinite;
  animation: rightArmAnimation 30s ease 7s infinite;
}

/* ANIMATIONS */

@keyframes headAnimation {
  30% {
    transform: rotate(0deg);
  }
  31% {
    transform: rotate(5deg);
  }
  33% {
    transform: rotate(7deg);
  }
  52% {
    transform: rotate(5deg);
  }
  53% {
    transform: rotate(-1deg);
  }
  53.3% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes leftAntennaAnimation {
  30% {
    transform: rotate(-30deg);
  }
  31% {
    transform: rotate(-25deg);
  }
  52% {
    transform: rotate(-25deg);
  }
  53% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

@keyframes rightAntennaAnimation {
  30% {
    transform: rotate(30deg);
  }
  31% {
    transform: rotate(25deg);
  }
  52% {
    transform: rotate(25deg);
  }
  53% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(30deg);
  }
}

@keyframes eyesAnimation {
  0% {
    top: 1.78em;
    left: 2.06em;
  }
  20% {
    top: 1.78em;
    left: 2.06em;
  }
  20.5% {
    top: 1em;
    left: 2.06em;
  }
  22.5% {
    top: 1em;
    left: 2.06em;
  }
  23% {
    top: 1.78em;
    left: 2.06em;
  }
  50% {
    top: 1.78em;
    left: 2.06em;
  }
  51% {
    top: 1.35em;
    left: 1.06em;
  }
  52% {
    top: 1.35em;
    left: 1.06em;
  }
  53% {
    top: 1.35em;
    left: 3.06em;
  }
  54% {
    top: 1.35em;
    left: 3.06em;
  }
  55% {
    top: 1.78em;
    left: 2.06em;
  }
  60% {
    top: 1.78em;
    left: 2.06em;
  }
  100% {
    top: 1.78em;
    left: 2.06em;
  }
}

@keyframes blinkAnimation {
  30% {
    transform: scale(1, 1);
  }
  31% {
    transform: scale(1, 0);
  }
  32% {
    transform: scale(1, 1);
  }
  98% {
    transform: scale(1, 1);
  }
  99% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes armsAnimation {
  30% {
    top: -0.18em;
  }
  33% {
    top: -0.38em;
  }
  36% {
    top: -0.18em;
  }
  94% {
    top: -0.18em;
  }
  97% {
    top: -0.38em;
  }
  100% {
    top: -0.18em;
  }
}

@keyframes leftArmAnimation {
  29% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  31% {
    transform: rotate(130deg);
  }
  32% {
    transform: rotate(110deg);
  }
  33% {
    transform: rotate(127deg);
  }
  34% {
    transform: rotate(124deg);
  }
  36% {
    transform: rotate(0deg);
  }
  91% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(30deg);
  }
  96% {
    transform: rotate(-4deg);
  }
  97% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rightArmAnimation {
  29% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(2deg);
  }
  33% {
    transform: rotate(-4deg);
  }
  35% {
    transform: rotate(-1deg);
  }
  36% {
    transform: rotate(0deg);
  }
  91% {
    transform: rotate(0deg);
  }
  94.8% {
    transform: rotate(-30deg);
  }
  96.5% {
    transform: rotate(4deg);
  }
  97.8% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* TEST CLASSES */

.hide {
  display: none;
}
/* .androidLogoContainer {
  transform: scale(0.2) !important;
} */
.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
  transform: scale(0.3);
  transition: 0.7s ease-in-out both;
}
@keyframes heartbeat {
  from {
    transform: scale(0.2);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.25);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.3);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.25);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(0.2);
    animation-timing-function: ease-out;
  }
}
.puff-in-center {
  animation: puff-in-center 1.4s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
@keyframes puff-in-center {
  0% {
    transform: scale(0.8);
    filter: blur(4px);
    opacity: 0;
    transition: all 0.7s ease-in-out;
  }
  100% {
    transform: scale(0.9);
    filter: blur(0px);
    opacity: 1;
    transition: all 0.7s ease-in-out;
  }
}
