.container-blog {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.blog-home {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  transform: scale(0.939);
  max-width: 84em;
}
.blog-col {
  width: 26%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  position: relative;
  margin: 22px;
}
.blog-img {
  border-radius: 7px;
  width: 90%;
  margin-bottom: 2rem;
}
.blog-category {
  position: absolute;
  left: 2rem;
  top: 2rem;
  transition: all 0.3s;
}
.blog-home .blog-category {
  display: block;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.blog-wrapper {
  text-align: left;
}
.blog-home .more-link {
  position: relative;
  clear: both;
  display: block;
  margin-top: 20px;
  padding: 0;
  font-weight: bold;
  transition: all 0.3s;
}
.blog-home .blog-col:hover .more-link {
  color: #444;
  padding-left: 20px;
}
.blog-home .more-link:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 0;
  height: 1px;
  background: #444;
  opacity: 0;
  transition: all 0.3s;
}
.blog-home .blog-col:hover .more-link:before {
  width: 15px;
  opacity: 1;
}
.blog-col iframe {
  transform: scale(0.78);
  box-shadow: 0 3px 8px black;
  border-radius: 10px;
}
/* .blog-col:hover iframe,
.blog-col:last-child:hover iframe {
  transform: scale(0.9) !important;
  transition: all 0.7s ease-in-out;
  background: #7c4fe0;
} */
.blog-col:hover .LinkBlog,
.blog-col:last-child:hover .LinkBlog {
  position: absolute;
  top: 84px;
  left: -21px;
  background: #7c4fe0;
  width: 112%;
  height: 78.3%;
  border-radius: 7px;
  transition: all 0.7s ease-in-out;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LinkBlog a span {
  position: absolute;
  top: 30%;
  background: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  display: none !important;
  transition: all 0.4s ease-in-out;
}
.blog-col:hover .LinkBlog a span {
  display: flex !important;
  /* transition: all 1s 0.8s ease-in-out; */
}
.blog-col .LinkBlog a span i {
  color: #7c4fe0;
  font-size: 22px;
  transition: all 0.4s ease-in-out;
}
.blog-col .LinkBlog:hover a span {
  width: 50%;
  height: 34%;
  cursor: pointer;
  border-radius: 25%;
  transition: all 0.4s ease-in-out;
}
.blog-col .LinkBlog:hover a span i {
  font-size: 56px;
  transition: all 0.4s ease-in-out;
}

.LinkBlog a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
/* .blog-col:nth-child(2) iframe {
  transform: scale(0.674);
} */

/* .blog-col:last-child iframe {
  transform: scale(0.766);
} */
.container-blog .section-title {
  padding-bottom: 0 !important;
}
.container-blog .section-title p {
  width: 138%;
}
@media screen and (max-width: 1250px) {
  .blog-col:hover .LinkBlog,
  .blog-col:last-child:hover .LinkBlog {
    position: absolute;
    top: 50px;
    left: 30px;
    background: #7c4fe0;
    width: 88%;
    height: 87.3%;
    border-radius: 7px;
    transition: all 0.7s ease-in-out;
    opacity: 0.7;
  }
  .blog-home {
    transform: scale(0.939);
    max-width: 84em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .blog-col {
    width: 100%;
    margin: 0 !important;
  }
  .blog-col iframe,
  .blog-col:last-child iframe {
    transform: scale(0.87);
  }
}

@media screen and (max-width: 950px) {
  .blog-col:hover .LinkBlog,
  .blog-col:last-child:hover .LinkBlog {
    position: absolute;
    top: 22px;
    left: 14px;
    background: #7c4fe0;
    width: 94.3%;
    height: 94.3%;
    border-radius: 7px;
    transition: all 0.7s ease-in-out;
    opacity: 0.7;
  }
  .blog-home {
    transform: scale(0.939);
    max-width: 84em;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .blog-col {
    margin: 0 !important;
  }
  .blog-col iframe,
  .blog-col:last-child iframe {
    transform: scale(0.94);
  }
}
