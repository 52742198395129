.realFrame-container #phone {
  position: relative;
  width: 295px;
  height: 600px;
  margin: 20px auto;
  background: #000;
  border: solid 3px lightgray;
  border-left: 4px solid white;
  border-bottom: 4px solid gray;
  border-right: 2px solid black;
  border-radius: 30px;
  z-index: 1;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
}

.realFrame-container #phone #screen {
  /* background: linear-gradient(to bottom, #b0b8cf 20%, #fad7c3 58%, #e7e7e7 94%); */
  position: relative;
  height: 470px;
  width: 269px;
  top: 61px;
  left: 15px;
}
#inlineFrameExample {
  transform: scale(0.77) translate(-62px, -180px) !important;
  width: 133%;
  height: 142%;
  border-radius: 12px;
}
.realFrame-container #phone .icons-phone {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 118%;
  height: 9%;
  transform: scale(0.8) translate(-32px, 8px);
}
.realFrame-container #phone .headContent {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.7) translate(0, -17px);
}
.phoneButton {
  width: 35px;
  height: 35px;
  background: none;
  border: none;
}
