.container-support {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.accordion {
  overflow-anchor: none;
  width: 79%;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0;
}
.accordion > .card {
  overflow: hidden;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion .card-header {
  position: relative;
  padding: 25px 0;
  background-color: transparent;
  border: none;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
}
.accordion .card-header a {
  transition: all 0.3s;
}
.collapse:not(.show) {
  display: none;
}
.accordion .card-body {
  padding: 5px 0 10px;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  text-align: left;
}
.card-body p {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.75;
  margin: 0 0 1.6em;
  color: #666;
}
.wpb_wrapper.sectionAccordion {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion .card-header h5 {
  margin-right: 50px;
  margin-bottom: 0;
  font-size: 1.25em;
  text-align: left;
}
.accordion [data-toggle="collapse"].collapsed:after {
  content: "\f107";
}
.purpleColor {
  color: #5f36bb;
}
/* .accordion [data-toggle="collapse"].collapsed:after,
.accordion [data-toggle="collapse"]:after {
  position: absolute;
  top: 30px;
  right: 20px;
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  font-size: 18px;
  font-weight: 900;
} */
.sectionAccordion {
  width: 100%;
  height: auto;
  display: contents;
}
.show {
  animation: fadeInUp 0.7s ease-in-out both;
}
