section.parallax {
  position: relative;
  overflow: hidden;
  color: #fff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top !important;
  background-size: cover;
  height: 31em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vc_custom_1622204834130 {
  background-image: url(../../assets/parallax/image-opt.png) !important;
}
.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
section.parallax h1,
section.parallax h2,
section.parallax h3,
section.parallax h4,
section.parallax h5,
section.parallax h6,
section.parallax p,
section.parallax a:hover {
  color: #fff;
}
p:empty {
  display: none;
}
@media (min-width: 768px) {
  .play-btn {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
  }
}
play-btn {
  display: block;
  width: 64px;
  height: 64px;
  color: #fff;
  margin: 0 auto;
  font-size: 20px;
  line-height: 64px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s;
}
.play-btn {
  background-color: #7c4fe0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerButton {
  color: white;
}
.parallaxContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-play {
  color: white;
}
.fa-play::before {
  color: whitesmoke;
}
.video-btn.wow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
}
#parallax-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
