.container-splash {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: 0% 0%;
  width: 0px;
  height: 0px;
}
.capsule {
  position: absolute;
  transform-origin: 500% 500%;
  animation: rotation 1.25s linear infinite;
}
.dot-splash {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  animation: pulse 2.5s linear infinite;
}
.capsule.one {
  top: -50px;
  left: -97.553px;
}
.capsule.two {
  top: 0px;
  left: -61.226px;
}
.capsule.three {
  top: -19.098px;
  left: -2.447px;
}
.capsule.four {
  top: -80.902px;
  left: -2.447px;
}
.capsule.five {
  top: -100px;
  left: -61.226px;
}

.capsule.one .dot-splash {
  background-color: #cccc00;
}
.capsule.two .dot-splash {
  background-color: #cc0000;
}
.capsule.three .dot-splash {
  background-color: #009900;
}
.capsule.four .dot-splash {
  background-color: #0000cc;
}
.capsule.five .dot-splash {
  background-color: #333333;
}

.capsule.one,
.capsule.one .dot-splash {
  animation-delay: -0s;
}
.capsule.two,
.capsule.two .dot-splash {
  animation-delay: calc(-2 / 5 * 1.25s);
}
.capsule.three,
.capsule.three .dot-splash {
  animation-delay: calc(-4 / 5 * 1.25s);
}
.capsule.four,
.capsule.four .dot-splash {
  animation-delay: calc(-6 / 5 * 1.25s);
}
.capsule.five,
.capsule.five .dot-splash {
  animation-delay: calc(-8 / 5 * 1.25s);
}

@keyframes rotation {
  0% {
    transform: rotate3d(0, 0, 1, 000deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes pulse {
  20% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(2, 2);
  }
  80% {
    transform: scale(1, 1);
  }
}
