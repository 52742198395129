:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  font-size: 2.1em;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  transition: all 0.3s;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

@font-face {
  font-family: "Permanent Marker";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/permanentMarker.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: smooth;
  }
}

.custom-btn,
.price-table.plan-popular,
.service-single.service-style-2:hover,
.testimonial-carousel .carousel-images .slick-center img,
.clients-slider .owl-dots .owl-dot span,
.screenshot-slider .owl-dots .owl-dot span,
.progress-heading .progress-value > span:before {
  border-color: #7c4fe0;
}

@media (min-width: 992px) {
  .section-title h3 {
    font-size: 2.25em;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
a,
h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover,
.custom-btn,
.play-btn:hover > i,
.button-store .custom-btn:hover i,
.button-store .custom-btn:hover p,
.button-store .custom-btn:hover p em,
.feature-box .box-icon .icon,
.feature-box:hover .box-text > h4,
.service-single:hover .icon,
.service-single.service-style-2 .icon,
.service-single.service-style-2:hover .icon,
.service-single.service-style-2:hover h5,
.overview-box:hover .icon,
.overview-list .fa-li,
.pricing-item .pricing-head .price,
.pricing-item .pricing-head .price .dollar-sign,
.op-mobile-menu .nav-menu li a:hover,
.fixed-menu .nav-menu li a.active,
.fixed-menu .nav-menu li.current_page_item > a,
.fixed-menu .nav-menu li.current_page_parent > a,
.page-header .page-header-content .breadcrumb li a:hover,
.testimonial-carousel .carousel-text .single-box i,
.accordion .card-header a:not(.collapsed),
.accordion .card-header a:hover,
.blog-home .blog-col:hover .blog-text h4 > a,
.price-table .icon,
.price-table:hover .plan-type,
.contact-info .icon,
footer a:hover,
footer .widget_social a:hover > i,
.blog-post .image-slider .arrows .arrow:hover,
.post-counters li a:hover,
.share-btn:hover > p,
.share-btn li:hover > a,
.share-btn li:hover > a > i,
.nav-links a:hover,
.widget ul li a:hover,
.sidebar .search-form button:hover,
.sidebar .search-form button:focus,
.comment-respond h3 small > a,
.author-social a:hover,
.wp-block-pullquote__citation,
.wp-block-pullquote cite,
.wp-block-pullquote footer,
.icon.colored i {
  color: #7c4fe0;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}
.p-0 {
  padding: 0 !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
body {
  --extendify--spacing--large: var(
    --wp--custom--spacing--large,
    clamp(2em, 8vw, 8em)
  );
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.App {
  text-align: center;
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button {
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.active,
.nav-link:hover {
  background-color: transparent;
  color: lightgray !important;
  /* border-radius: 7px; */
  border-bottom: 1px solid gray;
}

.bg-grey {
  background: #fbfbfe;
  padding-bottom: 1.2rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-btn,
.price-table.plan-popular,
.service-single.service-style-2:hover,
.testimonial-carousel .carousel-images .slick-center img,
.clients-slider .owl-dots .owl-dot span,
.screenshot-slider .owl-dots .owl-dot span,
.progress-heading .progress-value > span:before {
  border-color: #7c4fe0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
h4 {
  font-size: 1.5em;
}
.font-weight-bold {
  font-weight: 700 !important;
}
ul,
li {
  list-style: none;
}
.fa,
.fas {
  font-weight: 900;
}
.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}
.fa-li {
  left: -2 em;
  position: absolute;
  text-align: center;
  width: 2 em;
  line-height: inherit;
}
.btn,
.to-top:hover,
.play-btn,
.service-single:hover,
.overview-box .icon,
.overview-box:hover,
.fixed-menu .nav-menu li a.active span:after,
.fixed-menu .nav-menu li.current_page_item > a span:after,
.fixed-menu .nav-menu li.current_page_parent > a span:after,
.nav-menu li.dropdown .submenu li a:hover,
.nav-menu li.menu-item-has-children .sub-menu li.current_page_item > a,
.nav-menu li.page_item_has_children .children li.current_page_item > a,
.nav-menu li.menu-item-has-children .sub-menu li a:hover,
.nav-menu li.page_item_has_children .children li a:hover,
.search-wrapper .search-close-btn:hover:before,
.search-wrapper .search-close-btn:hover:after,
.clients-slider .owl-dots .active span,
.screenshot-slider .owl-dots .active span,
.blog-home .blog-col:hover .blog-category,
.page-title .blog-category > a:hover,
.pagination li a.active,
.pagination li a:hover,
.pagination li:last-child a,
.pagination li:first-child a,
.page-links .post-page-numbers.current,
.widget_categories li span,
.recent-post-image:before,
.author-social a:hover,
.member-info ul:after,
.progress .progress-bar,
.progress-heading .progress-value > span,
.tagcloud > a:hover,
blockquote {
  background-color: #7c4fe0;
}

.rounded-circle {
  border-radius: 50% !important;
  border: 1px solid #7c4fe0;
  padding: 8px;
}
.custom-btn,
.price-table.plan-popular,
.service-single.service-style-2:hover,
.testimonial-carousel .carousel-images .slick-center img,
.clients-slider .owl-dots .owl-dot span,
.screenshot-slider .owl-dots .owl-dot span,
.progress-heading .progress-value > span:before {
  border-color: #7c4fe0;
}
.radius {
  border-radius: 50%;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
section-title h3 {
  max-width: 88%;
}
.accordion [data-toggle="collapse"].collapsed:after,
.accordion [data-toggle="collapse"]:after {
  position: absolute;
  top: 30px;
  right: 20px;
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  font-size: 18px;
  font-weight: 900;
}
.accordion .card-header a {
  transition: all 0.3s;
}
[role="button"] {
  cursor: pointer;
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  color: inherit;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.hidden {
  display: none !important;
}
h5 {
  font-size: 1.25em;
  margin: 5px 0;
}
h6 {
  font-size: 1.125em;
  margin: 5px 0;
}
a,
h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover,
.custom-btn,
.play-btn:hover > i,
.button-store .custom-btn:hover i,
.button-store .custom-btn:hover p,
.button-store .custom-btn:hover p em,
.feature-box .box-icon .icon,
.feature-box:hover .box-text > h4,
.service-single:hover .icon,
.service-single.service-style-2 .icon,
.service-single.service-style-2:hover .icon,
.service-single.service-style-2:hover h5,
.overview-box:hover .icon,
.overview-list .fa-li,
.pricing-item .pricing-head .price,
.pricing-item .pricing-head .price .dollar-sign,
.op-mobile-menu .nav-menu li a:hover,
.fixed-menu .nav-menu li a.active,
.fixed-menu .nav-menu li.current_page_item > a,
.fixed-menu .nav-menu li.current_page_parent > a,
.page-header .page-header-content .breadcrumb li a:hover,
.testimonial-carousel .carousel-text .single-box i,
.accordion .card-header a:not(.collapsed),
.accordion .card-header a:hover,
.blog-home .blog-col:hover .blog-text h4 > a,
.price-table .icon,
.price-table:hover .plan-type,
.contact-info .icon,
footer a:hover,
footer .widget_social a:hover > i,
.blog-post .image-slider .arrows .arrow:hover,
.post-counters li a:hover,
.share-btn:hover > p,
.share-btn li:hover > a,
.share-btn li:hover > a > i,
.nav-links a:hover,
.widget ul li a:hover,
.sidebar .search-form button:hover,
.sidebar .search-form button:focus,
.comment-respond h3 small > a,
.author-social a:hover,
.wp-block-pullquote__citation,
.wp-block-pullquote cite,
.wp-block-pullquote footer,
.icon.colored i {
  color: #7c4fe0;
}
.top-bar,
.lock,
.timePhone,
.signal,
.battery {
  display: none !important;
}

@media screen and (max-width: 850px) {
  /* .banner-image {
    display: none !important;
  } */
}
.none {
  display: none !important;
}
.banner-text p,
.custom-btn p,
.subtitleFeatures,
.section-title p,
.service-single p,
.overviewParag,
.overviewParag-second,
.subtitleTesti {
  font-family: "Roboto", sans-serif !important;
}
.slideContentInner {
  display: none;
}
