.vc_custom_1622206318649 {
  background-image: url(../../assets/subscribe.jpg) !important;
}
section#subscribe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
}
.subscribe-content,
.section-title {
  width: 54em;
  height: auto;
  position: relative;
}
.accordion [data-toggle="collapse"].collapsed:after,
.accordion [data-toggle="collapse"]:after {
  position: absolute;
  top: 30px;
  right: 20px;
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  font-size: 18px;
  font-weight: 900;
}
input:not(.adminbar-input),
textarea {
  border: 1px solid #ddd !important;
  background-color: #fff !important;
  font-weight: normal !important;
  font-size: 14px !important;
  box-shadow: none !important;
  border-radius: 6px;
  height: 57px !important;
  padding: 0 20px;
  vertical-align: middle;
  transition: all 0.3s;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn,
.checkout-button,
.wp-block-button__link,
.wp-block-search__button,
input.btn,
input.search-submit {
  position: relative;
  text-align: center;
  padding: 14px 30px;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
  border: 0 !important;
  border-radius: 6 px;
  transition: all 0.3s;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
.btn-block {
  display: block;
  width: 100%;
  border-radius: 7px;
  height: 57px;
}
#subscribe-result {
  display: none;
  margin: 30px 0 0 0;
}
.subscribe-content {
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscribesection-title h3 {
  max-width: 76%;
  padding-top: 2em;
}

.subscribe-bottom {
  font-size: 0.88rem;
}
#subscribe-form {
  width: 100% !important;
}
.buttonSubContent {
  width: 100%;
}
