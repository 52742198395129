:root {
  --i: #7c4fe0;
  --i1: #4eb7ff;
  --i2: #fd6494;
  --i3: #37ba82;
}
.wpb_wrapperpres {
  width: 54%;
}

.animeFeaturesBlockContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82%;
  min-height: 100%;
  margin: 15px 15px 15px 0;
}
.animFeaturesContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
}
.service-box {
  position: relative;
  width: 99%;
  height: auto;
  background: white;
  box-shadow: 0 0 22px lightgray;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.animFeatureIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-box:hover .animFeatureIcon {
  top: 12px;
  left: 41%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.animFeatureIcon i {
  font-size: 5em;
  color: #fff;
}
.service-box:hover .animFeatureIcon i {
  font-size: 2em;
}
.animeFeaturesContent {
  position: relative;
  color: gray;
  padding-top: 65px;
  padding-bottom: 1rem;
  max-width: 90%;
  z-index: 1;
}
/* .animeFeaturesContent h4 {
  position: absolute;
  top: 5px;
  left: 10px;
}
.animeFeaturesContent p {
  margin-top: 5em;
} */
