.mt30 {
  margin-top: 30px;
}
.wrapper {
  margin: 0 auto 100px auto;
  max-width: 960px;
}

.stage {
  list-style: none;
  padding: 0;
}

/*************************************
Build the scene and rotate on hover
**************************************/

.scene {
  width: 260px;
  height: 400px;
  margin: 30px;
  float: left;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}

.movie {
  width: 260px;
  height: 400px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-130px);
  -moz-transform: translateZ(-130px);
  transform: translateZ(-130px);
  -webkit-transition: -webkit-transform 350ms;
  -moz-transition: -moz-transform 350ms;
  transition: transform 350ms;
}

.movie:hover {
  -webkit-transform: rotateY(-78deg) translateZ(20px);
  -moz-transform: rotateY(-78deg) translateZ(20px);
  transform: rotateY(-78deg) translateZ(20px);
}

/*************************************
Transform and style the two planes
**************************************/

.movie .poster,
.movie .info {
  position: absolute;
  width: 260px;
  height: 400px;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.movie .poster {
  -webkit-transform: translateZ(130px);
  -moz-transform: translateZ(130px);
  transform: translateZ(130px);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 2px 36px lightgrey;
}

.movie .info {
  -webkit-transform: rotateY(90deg) translateZ(130px);
  -moz-transform: rotateY(90deg) translateZ(130px);
  transform: rotateY(90deg) translateZ(130px);
  border: 4px solid #fff;
  font-size: 0.75em;
}

/*************************************
Shadow beneath the 3D object
**************************************/

.csstransforms3d .movie::after {
  content: "";
  width: 260px;
  height: 260px;
  position: absolute;
  bottom: 0;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: rotateX(90deg) translateY(130px);
  -moz-transform: rotateX(90deg) translateY(130px);
  transform: rotateX(90deg) translateY(130px);
  -webkit-transition: box-shadow 350ms;
  -moz-transition: box-shadow 350ms;
  transition: box-shadow 350ms;
}

.csstransforms3d .movie:hover::after {
  box-shadow: 20px -5px 50px rgba(0, 0, 0, 0.3);
}

/*************************************
Movie information
**************************************/

.info header {
  color: #fff;
  padding: 7px 10px;
  font-weight: bold;
  height: 195px;
  background-size: contain;
  background-repeat: no-repeat;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
}

.info header h1 {
  margin: 0 0 2px;
  font-size: 1.4em;
}

.info header .rating {
  border: 1px solid #fff;
  padding: 0px 3px;
}

.info p {
  padding: 1.2em 1.4em;
  margin: 2px 0 0;
  color: #395065;
  text-align: justify;
  line-height: 1.4em;
  font-family: sans-serif;
}

/*************************************
Generate "lighting" using box shadows
**************************************/

.movie .poster,
.movie .info,
.movie .info header {
  -webkit-transition: box-shadow 350ms;
  -moz-transition: box-shadow 350ms;
  transition: box-shadow 350ms;
}

.csstransforms3d .movie .poster {
  box-shadow: inset 0px 0px 40px rgba(255, 255, 255, 0);
  background-size: cover;
  background-position: top;
}

.csstransforms3d .movie:hover .poster {
  box-shadow: inset 300px 0px 40px rgba(255, 255, 255, 0.8);
}

.csstransforms3d .movie .info,
.csstransforms3d .movie .info header {
  box-shadow: inset -300px 0px 40px rgba(0, 0, 0, 0.5);
}

.csstransforms3d .movie:hover .info,
.csstransforms3d .movie:hover .info header {
  box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0);
}

/*************************************
Posters and still images
**************************************/

.scene:nth-child(1) .movie .poster {
  background-image: url("../../../assets/prices/student-full.svg");
}

.scene:nth-child(2) .poster {
  background-image: url("../../../assets/prices/autonomous.svg");
}

.scene:nth-child(3) .poster {
  background-image: url("../../../assets/prices/teacher-full.svg");
}

.scene:nth-child(1) .info header {
  background-image: url("../../../assets/prices/student-land.svg");
}

.scene:nth-child(2) .info header {
  background-image: url("../../../assets/prices/autonomous-land.svg");
}

.scene:nth-child(3) .info header {
  background-image: url("../../../assets/prices/teacher-land.svg");
}

/* US VERSION */

.sceneUs:nth-child(1) .movie .poster {
  background-image: url("../../../assets/prices/student-full-us.svg") !important;
}

.sceneUs:nth-child(2) .poster {
  background-image: url("../../../assets/prices/autonomous-full-us.svg") !important;
}

.sceneUs:nth-child(3) .poster {
  background-image: url("../../../assets/prices/teacher-full-us.svg") !important;
}

/*************************************
Fallback
**************************************/
.no-csstransforms3d .movie .poster,
.no-csstransforms3d .movie .info {
  position: relative;
}

/*************************************
Media Queries
**************************************/
@media screen and (max-width: 60.75em) {
  .scene {
    float: none;
    margin: 30px auto 60px;
  }
}
.poster i,
.info header i {
  color: #7c4fe0;
  font-size: 2.4rem;
  margin-top: 1rem;
}
.info header i {
  color: #7c4fe0;
  font-size: 1rem;
  margin-top: -1rem;
}
.blockHeader {
  margin-top: 7rem;
}
.blockHeader:nth-child(2) {
  margin-top: 8rem !important;
}
.blockHeader:last-child {
  margin-top: 8rem;
}
.blockHeader span {
  color: black;
}
.parag {
  padding: 0.8rem !important;
  margin: -8px 0 0 !important;
}
@media screen and (max-width: 900px) {
  .container-pricing .section-title {
    height: auto;
    position: relative;
    padding-bottom: 0 !important;
  }
  .pricing3d {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 9rem;
  }
  /* .priceAnimateContainer {
    margin-top: 17rem;

  } */
}
.pricing3d .container {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.pricing3d .container .wrapper {
  height: auto !important;
}
