:root {
  --phoneWidth: 400px;
  --screenWidth: 368px;
}

.phoneFrame-container {
  transform: rotateY(50deg) scale(0.6) rotatex(14deg) translate(0, -1rem);
  position: absolute;
  z-index: 99;
  filter: drop-shadow(-6px 9px 2px black);
  border-radius: 51px;
  transition: all 0.7s ease-in-out;
}
.phoneFrame-container2 {
  transform: rotateY(-48deg) scale(0.6) rotatex(11deg) translate(18rem, 5rem);
  z-index: 8;
  position: absolute;
  filter: drop-shadow(15px 12px 5px black);
  border-radius: 51px;
}
span.timePhone {
  position: absolute;
  top: 59px;
  color: white;
  left: 77px;
}
.phoneFrame-container2 #phone {
  border: solid 3px lightgray;
  border-right: 4px solid white;
  border-bottom: 4px solid gray;
  border-left: 2px solid black;
  box-shadow: 2px 0px 6px white, 9px 2px 2px grey, -2px 4px 1px black;
}
.phoneFrame-container #phone {
  box-shadow: inset 0 -13px 7px 0 rgb(0 0 0), -3px 0px 6px white,
    -10px 8px 2px grey;
}
#phone {
  position: relative;
  width: var(--phoneWidth);
  height: 600px;
  margin: 20px auto;
  background: #000;
  border: solid 3px lightgray;
  border-left: 4px solid white;
  border-bottom: 4px solid gray;
  border-right: 2px solid black;
  border-radius: 30px;
  z-index: 1;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
}
/* #phone:before {
  position: absolute;
  content: "";
  background: #666;
  border-radius: 40px;
  width: 55px;
  height: 10px;
  left: 130px;
  top: 20px;
}
#phone:after {
  position: absolute;
  content: "";
  background: #666;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 14px;
  left: 190px;
} */

.phoneFrame-container #phone #screen {
  background-image: url(../../assets/prfile/1-light-opt.jpg);
  background-size: 97% 97%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  object-fit: cover;
}
.phoneFrame-container2 #phone #screen {
  background-image: url(../../assets/prfile/1-dark-opt.jpg);
  background-size: 97% 97%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  object-fit: cover;
}
.icons,
.icons-bottom,
.weather-widget,
.magnifying-glass,
.search-bar,
.time-and-date {
  display: none !important;
}
#screen {
  /* background: linear-gradient(to bottom, #b0b8cf 20%, #fad7c3 58%, #e7e7e7 94%); */
  position: absolute;
  height: 470px;
  width: var(--screenWidth);
  top: 55px;
  left: 15px;
}
.headerPhone {
  width: var(--screenWidth);
}
.icon-back {
  position: relative;
  /* top: 555px; */
  /* left: 337px; */
  width: 23px;
  height: 10px;
  border-top: solid 2px #fff;
  border-left: solid 0 transparent;
  border-right: solid 2px #fff;
  border-bottom: solid 2px #fff;
  border-radius: 0 40px 40px 0;
}
.icon-back:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -4px;
  left: -8px;
  border-top: solid 3px transparent;
  border-bottom: solid 3px transparent;
  border-right: solid 12px #fff;
}
.icon-home {
  /* position: relative;
  top: 530px; */
  width: 2px;
  height: 20px;
  background: #fff;
  /* left: 100px; */
  transform: scale(0.8) rotate(56deg);
}
.icon-home:after {
  position: absolute;
  content: "";
  top: -13px;
  left: 9px;
  width: 2px;
  height: 20px;
  background: #fff;
  transform: rotate(70deg);
}
.icon-home:before {
  position: absolute;
  content: "";
  background: transparent;
  top: 4px;
  left: -1px;
  width: 28px;
  height: 10px;
  border: solid 2px #fff;
  border-top: transparent;
  transform: rotate(305deg);
}
.icon-recent {
  /* position: absolute; */
  background: transparent;
  /* top: 552px;
  left: 50px; */
  width: 30px;
  height: 15px;
  border: solid 2px #fff;
}
.icon-recent:after {
  /* position: absolute; */
  display: none;
  content: "";
  background: transparent;
  top: -6px;
  left: 4px;
  width: 30px;
  height: 15px;
  border: solid 2px #fff;
  border-left: solid 0 transparent;
  border-bottom: solid 0 transparent;
}
.top-bar {
  position: absolute;
  background: #666;
  width: var(--screenWidth);
  height: 30px;
  left: 15px;
  top: 55px;
}
.lock {
  background: #fafafa;
  position: absolute;
  width: 15px;
  height: 10px;
  top: 69px;
  left: 25px;
  z-index: 10;
}
.lock:after {
  position: absolute;
  content: "";
  background: transparent;
  width: 5px;
  height: 10px;
  top: -8px;
  left: 2px;
  border-radius: 40px;
  border: solid 3px #fff;
  z-index: -1;
}
.battery {
  position: absolute;
  width: 14px;
  height: 16px;
  background: #ccc;
  left: 358px;
  top: 63px;
}
.battery:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 5px;
  background: #ccc;
  left: 3px;
  top: -3px;
}
.battery:after {
  position: absolute;
  content: "";
  width: 14px;
  height: 11px;
  background: #3792b4;
  left: 0px;
  top: 5px;
}
.battery span:first-of-type {
  position: absolute;
  left: -35px;
  color: #fff;
  font-size: 14px;
}
.battery span:nth-of-type(2) {
  position: absolute;
  left: 22px;
  color: #fff;
  font-size: 14px;
}
.signal {
  position: absolute;
  width: 0;
  height: 0;
  top: 63px;
  left: 288px;
  border-top: solid 7px transparent;
  border-right: solid 12px #fff;
  border-bottom: solid 7px #fff;
  border-left: solid 12px transparent;
}
.signal:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -12px;
  left: -12px;
  border-top: solid 7px transparent;
  border-right: solid 0 transparent;
  border-bottom: solid 12px orange;
  border-left: solid 20px transparent;
}
.search-bar {
  position: absolute;
  width: 85%;
  height: 30px;
  top: 96px;
  left: 25px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
}
.magnifying-glass {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: transparent;
  border: solid 2px #fff;
  top: 100px;
  left: 240px;
}
.magnifying-glass:after {
  position: absolute;
  content: "";
  width: 3px;
  height: 8px;
  background: #fff;
  transform: rotate(-35deg);
  left: 10px;
  top: 11px;
}
.search-bar span {
  line-height: 30px;
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.7);
}
.time-and-date p {
  position: absolute;
  top: 120px;
  left: 30px;
  font-size: 30px;
  color: #fff;
  text-shadow: 13px 0px 5px rgba(75, 75, 75, 0.5);
}
.time-and-date span {
  position: absolute;
  top: 39px;
  font-size: 11px;
  width: 90px;
  left: 7px;
  text-shadow: none;
}
.weather-widget {
  z-index: 1;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.cloud {
  position: absolute;
  top: 155px;
  left: 130px;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 40px;
}
.cloud:before {
  position: absolute;
  content: "";
  top: 12px;
  left: 10px;
  background: #fff;
  width: 20px;
  height: 13px;
  border-radius: 40px;
  z-index: 1;
}
.cloud:after {
  position: absolute;
  content: "";
  top: 8px;
  left: 15px;
  background: #ddd;
  width: 15px;
  height: 13px;
  border-radius: 40px;
}
.moon {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 146px;
  left: 135px;
  box-shadow: 5px 5px 0 0 #f8f400;
  transform: rotate(10deg);
}
.weather-widget span {
  color: #fff;
  position: absolute;
  top: 188px;
  left: 130px;
  font-size: 12px;
}
.icons li {
  position: relative;
  display: inline-block;
  color: #fff;
  top: 255px;
  left: 32px;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.icons li:first-child {
  background: #c6357f;
  border-radius: 15px;
}
.icons li:nth-child(2) {
  background: #ffa300;
  border-radius: 10px;
}
.icons li:nth-child(3) {
  background: #1ea35f;
  border-radius: 10px;
}
.icons li:nth-child(4) {
  background: #5b73ff;
  border-radius: 10px;
}
.icons li:nth-child(5) {
  background: #db9a4d;
  border-radius: 10px;
}
.icons li:nth-child(6) {
  background: #ffffff;
  border-radius: 10px;
}
.icons li:nth-child(7) {
  background: #4ad37f;
  border-radius: 10px;
}
.icons li:last-child {
  background: #eee;
  border-radius: 10px;
}
.instagram {
  position: absolute;
  background: transparent;
  border: solid 3px #fff;
  border-radius: 10px;
  display: block;
  width: 30px;
  height: 30px;
  display: block;
  top: 7px;
  left: 7px;
}
.instagram:after {
  position: absolute;
  content: "";
  border: solid 3px #fff;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  top: 6px;
  left: 6px;
}
.instagram:before {
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 10px;
  width: 4px;
  height: 4px;
  top: 3px;
  left: 23px;
}
.icons p {
  position: relative;
  display: block;
  font-size: 12px;
  color: #fafafa;
  text-align: center;
  margin-top: 13px;
}
.icons p:first-of-type,
.icons p:nth-of-type(2),
.icons p:nth-of-type(3),
.icons p:nth-of-type(4) {
  top: 40px;
}
.icons p:nth-of-type(5) {
  top: 40px;
}
.icons-bottom {
  position: absolute;
  bottom: 296px;
}
.icons-phone {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 14%;
}
.gallery {
  position: absolute;
  background: #00a8ff;
  width: 51px;
  height: 15px;
  top: 35px;
  left: 0;
  display: block;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 3;
}
.circle {
  position: absolute;
  top: 25px;
  left: 15px;
  background: #fafafa;
  width: 20px;
  height: 20px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
}
.circle:before {
  position: absolute;
  content: "";
  background: #fff;
  width: 35px;
  height: 30px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
  top: -8px;
  left: -8px;
  opacity: 0.7;
}
.circle:after {
  position: absolute;
  content: "";
  background: #eee;
  width: 45px;
  height: 40px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
  top: -16px;
  left: -13px;
  opacity: 0.5;
}
.messaging {
  position: absolute;
  top: 9px;
  left: 8px;
  background: #fff;
  width: 35px;
  height: 28px;
  border-radius: 5px;
}
.messaging:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 22px;
  left: 13px;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
  border-top: solid 12px #fff;
}
.music {
  position: absolute;
  background: transparent;
  border: solid 5px #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  top: 5px;
  left: 5px;
}
.music:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 5px;
  left: 8px;
  border-top: solid 10px transparent;
  border-left: solid 20px #fafafa;
  border-bottom: solid 10px transparent;
}
.contacts {
  position: absolute;
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  top: 10px;
  left: 17px;
}
.contacts:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: solid 10px #fff;
  border-left: solid 15px #fff;
  border-right: solid 10px #fff;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  top: 18px;
  left: -5px;
}
.clock {
  position: absolute;
  background: #888;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  top: 8px;
  left: 7px;
}
.clock:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 12px;
  background: #fff;
  top: 5px;
  left: 17px;
}
.clock:after {
  position: absolute;
  content: "";
  width: 14px;
  height: 2px;
  background: #fff;
  top: 15px;
  left: 18px;
}
.clock-hands {
  position: absolute;
  width: 1px;
  height: 15px;
  background: #222;
  top: 21px;
  left: 20px;
  transform: rotate(40deg);
}
.clock-hands:after {
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 2px;
  background: #222;
  top: 0px;
  left: -1px;
}
.phone {
  position: absolute;
  background: #777;
  width: 10px;
  height: 35px;
  top: 12px;
  left: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transform: rotate(-50deg);
}
.phone:before {
  position: absolute;
  content: "";
  background: #777;
  width: 15px;
  height: 13px;
  top: -1px;
  left: 3px;
  border-radius: 5px;
}
.phone:after {
  position: absolute;
  content: "";
  background: #777;
  width: 15px;
  height: 13px;
  top: 22px;
  left: 3px;
  border-radius: 5px;
}
.voice {
  position: absolute;
  background: #e8641b;
  width: 10px;
  height: 23px;
  left: 20px;
  top: 8px;
  border-radius: 20px;
}
.voice:before {
  position: absolute;
  content: "";
  width: 22px;
  height: 12px;
  top: 14px;
  left: -8px;
  background: transparent;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border: solid 2px #e8641b;
  border-top: 0;
}
.voice:after {
  position: absolute;
  content: "";
  width: 3px;
  height: 9px;
  top: 28px;
  left: 4px;
  background: #e8641b;
}
.icons-bottom li {
  position: relative;
  display: inline-block;
  top: 280px;
  left: 30px;
  width: 45px;
  height: 45px;
  margin-right: 8px;
  z-index: 1;
}
.camera {
  position: absolute;
  background: #787;
  display: block;
  width: 45px;
  height: 32px;
  z-index: 1;
  border-radius: 2px;
}
.camera:before {
  position: absolute;
  background: transparent;
  content: "";
  width: 20px;
  height: 0;
  top: -7px;
  left: 11px;
  border-bottom: solid 10px #787;
  border-left: solid 2px transparent;
  border-right: solid 2px transparent;
  z-index: -1;
}
.camera:after {
  position: absolute;
  background: #fff;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 10px;
  left: 36px;
  top: 6px;
}
.camera-lens {
  position: absolute;
  background: #777;
  width: 18px;
  height: 18px;
  z-index: 1;
  left: 10px;
  top: 2px;
  border: solid 3px #fff;
  border-radius: 20px;
}
.camera-lens:after {
  position: absolute;
  content: "";
  background: #898;
  width: 12px;
  height: 12px;
  z-index: 1;
  left: 3px;
  top: 3px;
  border-radius: 20px;
  z-index: 1;
}
.youtube {
  position: absolute;
  background: #ac392f;
  top: -3px;
  width: 45px;
  height: 35px;
  border-radius: 5px;
}
.youtube:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 9px;
  left: 17px;
  border-top: solid 8px transparent;
  border-left: solid 15px #fff;
  border-bottom: solid 8px transparent;
}
.appdrawer {
  position: absolute;
  background: #ddd;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  top: -5px;
}
.appdrawer-up {
  position: absolute;
  background: #888;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 9px;
  left: 8px;
}
.appdrawer-up:before {
  position: absolute;
  content: "";
  background: #888;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 0px;
  left: 9px;
}
.appdrawer-up:after {
  position: absolute;
  content: "";
  background: #888;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 0px;
  left: 18px;
}
.appdrawer-down {
  position: absolute;
  background: #888;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 17px;
  left: 8px;
}
.appdrawer-down:before {
  position: absolute;
  content: "";
  background: #888;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 0px;
  left: 9px;
}
.appdrawer-down:after {
  position: absolute;
  content: "";
  background: #888;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 0px;
  left: 18px;
}
.mail {
  position: absolute;
  width: 47px;
  height: 35px;
  background: #dd76a0;
  border-radius: 2px;
  top: -2px;
  left: -3px;
  z-index: 1;
}
.mail:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: solid 23px transparent;
  border-right: solid 24px transparent;
  border-top: solid 18px #fafafa;
  border-radius: 2px 2px 2px 0;
  z-index: 1;
}
.mail:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: solid 15px transparent;
  border-right: solid 30px rgba(0, 0, 0, 0.1);
  border-bottom: solid 21px transparent;
  border-radius: 2px 2px 2px 0;
  left: 18px;
  z-index: -1;
}
.file {
  position: absolute;
  background: #72affd;
  width: 45px;
  height: 35px;
  border-radius: 2px;
  top: -2px;
}
.file-button {
  position: absolute;
  border: solid 2px #fff;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  left: 17px;
  top: 6px;
}
.file-button:before {
  position: absolute;
  content: "";
  background: #fff;
  width: 1px;
  height: 13px;
  top: -10px;
  left: -2px;
}
.file-button:after {
  position: absolute;
  content: "";
  background: #fff;
  width: 1px;
  height: 13px;
  top: -10px;
  left: 6px;
}
.icons,
.icons-bottom {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  transform: scale(0.8) translate(-25px, 0);
}
.headPhone {
  content: "";
  background: #666;
  border-radius: 40px;
  width: 55px;
  height: 10px;
  margin-right: 2.8rem;
}
.camPhone {
  content: "";
  background: #666;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.headContent {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
