footer {
  position: relative;
  clear: both;
  color: #b0b0b0;
  background: #1a191d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
footer .footer-widgets {
  position: relative;
  clear: both;
  padding-top: 100px;
  padding-bottom: 100px;
}
footer .row .col-12 .widget:last-child {
  margin-bottom: 0;
}
footer .widget_social {
  cursor: default;
  line-height: 1;
}
footer p {
  color: #b0b0b0;
}
footer .widget_social .author-social {
  text-align: left;
}
.author-social {
  line-height: 1;
}
.author-social .social {
  display: inline-block;
  cursor: default;
}
footer .widget_social .author-social a {
  width: auto;
  height: auto;
  margin-right: 15px;
  background-color: transparent;
}
footer .widget_social a:first-child {
  margin-left: 0;
}
footer .widget_social a {
  margin: 0 7px;
}
footer .footer-widgets a {
  color: #b0b0b0;
}
.author-social a {
  display: inline-block;
  margin-right: 5px;
  background-color: #e9e9e9;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
}
footer .row .col-12 .widget:last-child {
  margin-bottom: 0;
}
footer .widget h6 {
  color: #fff;
  margin: 0 0 30px 0;
}
footer .widget_nav_menu .menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget > div > ul > li:first-child {
  padding-top: 0;
}
footer .widget_nav_menu .menu li {
  margin-bottom: 10px;
  border: 0;
  padding: 0;
}
.widget ul li a,
.wp-block-categories li a {
  color: inherit;
  font-weight: 600;
  text-decoration: none !important;
  transition: all 0.3s;
}
.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
footer .footer-widgets {
  position: relative;
  clear: both;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-widgets {
  width: 82%;
  display: flex;
  align-items: center;
}
.widget_social {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.widget {
  width: 20%;
  padding: 2rem;
}
.textFooter1 {
  width: 80%;
  line-height: 1.4rem;
  margin-bottom: 2rem;
}
footer .widget h6 {
  color: #fff;
  margin: 0 0 30px 0;
}
footer .widget .textwidget .button-store-footer .custom-btn {
  border-color: #666;
}
.textwidget .button-store-footer .custom-btn {
  min-width: 195px;
  padding: 10px 25px;
  border: 1px solid #fff;
  font-weight: 500;
  border-radius: 3px;
}
.textwidget .button-store-footer .custom-btn i {
  font-size: 27px;
  margin-right: 10px;
  color: #fff;
  transition: all 0.3s;
}
.textwidget .button-store-footer .custom-btn p {
  display: block;
  font-size: 17px;
  line-height: 1.4;
  color: #fff;
  font-weight: 700;
  margin: 0;
  transition: all 0.3s;
}
.textwidget .button-store-footer .custom-btn p em {
  display: block;
  clear: both;
  font-style: normal;
  font-size: 12px;
  margin: 0;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s;
}
.custom-btn:hover {
  background: white;
}
.custom-btn:hover,
.custom-btn:hover i,
.custom-btn:hover p,
.custom-btn:hover p em {
  transition: all 0.7s ease-in-out;
  color: #7c4fe0 !important;
}
.downloadWidget {
  width: 34%;
}
.textwidget .button-store-footer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.textwidget .button-store-footer:first-child {
  margin-right: 0 !important;
}
.button-store-footer .custom-btn:first-child {
  margin-bottom: 2rem !important;
}
.custom-btn:hover p em {
  color: #7c4fe0;
}
.footer-logo {
  width: 40px;
}
.logoFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoFooter h1 {
  font-size: 18px !important;
  width: 90%;
}
