body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  scroll-behavior: smooth;
}

@media screen and (max-width: 900px) {
  body {
    overflow-x: hidden !important;
  }
  .wave {
    position: absolute;
    left: 0;
    width: 457% !important;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
    overflow: hidden;
  }
  .wave .wave-one,
  .wave .wave-two,
  .wave .wave-three {
    overflow: hidden !important;
  }
  .vc_row .wpb_row .vc_row-fluid,
  .service-wrapper,
  .vc_section,
  .wpb_wrapper,
  .features-presentation,
  .container-features {
    max-width: 100% !important;
    padding: 0;
    margin: 0;
  }
  .container-features {
    overflow: hidden;
  }
  .vc_row {
    width: 90% !important;
  }
  .container.firstview {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .services-card {
    flex-wrap: wrap;
    margin-left: 4%;
  }
  .banner {
    display: flex;
    flex-direction: column;
    height: 951px !important;
    /* height: 1415px !important; */
  }
  .banner-image {
    transform: translate(-7em, -10em) scale(0.5) !important;
  }
  .banner-text {
    margin-top: 80%;
    width: 300px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .banner-text h1 {
    font-size: 2.8rem !important;
  }
  .banner-text p {
    text-align: center !important;

    width: 288px;
    margin-left: 12%;
  }
  .button-store .custom-btn {
    min-width: 167px !important;
    padding: 8px 4px !important;
    border-radius: 8px;
  }
  .button-store.wow.is-inViewport {
    margin-left: -7%;
  }
  .mi-slider ul li img {
    width: 40px !important;
  }
  #ListLevel,
  #ListBadge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ListLevel {
    width: 125%;
  }
  /* #ListBadge {
    display: none !important;
  } */
  .mi-slider {
    transform: scale(0.62) translate(0, -4rem) !important;
  }
  .visible {
    left: 8px;
    transition: all 400ms;
    transform: rotate(360deg);
    bottom: 1em;
  }
  footer .footer-widgets {
    flex-wrap: wrap;
  }
  .features-presentation {
    flex-wrap: wrap;
  }
  .figure {
    margin-left: 7rem;
    transform: scale(0.7);
  }
  .testIt-content {
    transform: translate(-4rem, 2rem) rotate(32deg) !important;
  }
  .desktopLogos {
    width: 176% !important;
  }
  .section-title p {
    width: 250px !important;
  }
  .features-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .play-btn {
    width: 123px;
    height: 123px;
  }
  .fa-play {
    font-size: 32px;
  }
  .lightcase-contentInner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .lightcase-contentInner video {
    margin-left: 5rem;
  }
  .team-details p {
    max-width: 77% !important;
  }
  .section-title.text-center h3 {
    width: 249px;
    font-size: 28px;
  }
  .service-single.res-margin.wow.is-inViewport {
    margin-bottom: 2rem;
  }
  .header {
    padding: 20px 1px !important;
  }
  footer .footer-widgets {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width: 303%; */
    overflow: hidden;
  }
  .widget {
    width: 99% !important;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align: center !important;
  }
  .contact-container {
    flex-direction: column;
  }
  .contact-info-container {
    width: 106%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div#wpcf7-f131-p61-o1 {
    width: 100%;
  }
  .wpb_column.vc_column_container.vc_col-sm-8.form-container div form {
    width: 242%;
    margin-left: -59%;
  }
  section#overview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .overview-first-section,
  .overview-second-section {
    flex-direction: column;
    max-width: 100%;

    padding: 0;
    padding-top: 25px;
  }
  .overview-item {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    transform: scale(0.7) translate(-154px, 35px);
  }
  .wrapperPhone1 {
    transform: translate(5rem, 19rem) !important;
  }

  .container-overview,
  .contentSecondSection {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }
  .second {
    padding: 0;
    width: 100%;
  }
  .section-title-overview-first {
    transform: translate(6.33rem, -11rem) !important;
  }
  .banner-image.overviewBanner.is-inViewport {
    transform: translate(-3rem, -5px) scale(0.6) !important;
    z-index: 99999;
  }
  .overview-box .icon {
    transform: scale(0.5);
  }
  .overview-box .second p {
    display: none;
  }
  .second h6 {
    font-size: 1rem !important;
    margin: 0;
    padding: 0;
    width: 190px;
  }
  .contentSecondSection {
    transform: scale(0.78);
  }
  .contentSecondSection {
    transform: scale(0.6) translate(16rem, 18rem) !important;
  }
  .contentSecondSection .overview-box {
    min-height: 102px;
  }
  .wpb_wrapper.left {
    margin-top: 0rem;
    width: 100% !important;
  }
  p.overviewParag-second {
    text-align: center;
  }
  .section-title-overview {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .wpb_text_column.wpb_content_element.overview-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .phoneFrame-overview1-bottom #phone #screen,
  .phoneFrame-overview-bottom #phone #screen {
    width: 87%;
    transform: translate(-0.4rem, 0px);
  }
  .phoneFrame-overview-bottom {
    width: 59%;
    transform: rotate3d(0, 67, 0, -32deg) !important;
  }
  .phoneFrame-overview1-bottom {
    width: 63%;
    transform: rotate3d(0, 67, 0, 33deg) !important;
  }
  .pictureContnt {
    padding-bottom: 0 !important;
  }
  .phoneFrame-overview1:hover {
    transform: rotateY(-50deg) scale(1) rotatex(1deg) translate(10rem, -4rem) !important;
  }
  .visible {
    bottom: 1.6em !important;
  }
  .mobileresponsive {
    width: 397px !important;
    transform: translate(-125px, 10px) !important;
  }
  .carousel-container {
    transform: scale(1.9);
  }
  .slideContentInner {
    display: none !important;
  }
  .slides > button:last-child {
    right: -105% !important;
    top: 45px !important;
    color: white;
    transform: scale(0.5) !important;
  }
  .slides > button:first-child {
    left: -103% !important;
    top: 45px !important;
    color: white;
    transform: scale(0.5) !important;
  }
  span.autoSlideButton {
    bottom: -4rem !important;
    transform: scale(0.5);
  }
  section#screenshots {
    padding-bottom: 12rem !important;
  }
  .slideContentInner .slideSubtitle,
  .slideContentInner .slideTitle {
    font-size: 71% !important;
    padding-right: 1px;
  }
  .blog-home {
    transform: scale(0.8) translate(0, 0rem) !important;
    max-width: 84em !important;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: -13rem !important;
  }
  .headContact {
    margin-top: -5rem;
  }
  .testIt-content.content-back {
    transform: translate(-55px, -35px) rotate(-28deg) !important;
  }
  .testIt-content.content-back .arrowText {
    font-size: 23px;
  }
  form#subscribe-form {
    width: 38% !important;
  }
  .wpb_wrapper.subscribe-bottom p {
    width: 42%;
  }
  .wpb_wrapper.subscribe-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  /* #clients .slideshow-container {
    max-width: 92% !important;
  } */
  .beginner,
  .intermediate,
  .confirmed,
  .expert,
  .attendance {
    width: 100px !important;
    margin-bottom: 1rem;
  }
  .bestLearn {
    width: 155px !important;
  }
  .curious {
    width: 130px !important;
  }
  .text {
    width: 158%;
  }
  section#clients {
    height: 24vh;
    position: relative;
  }
  .iconDesktop.fab.fa-apple {
    position: absolute;
    top: 0;
    transform: scale(0.5) translate(0vw, -1.6vw) !important;
  }
  .testimonial-carousel .carousel-text .single-box p {
    width: 250px !important;
  }
  .carousel-text .single-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .counter-content p {
    font-size: 0.7rem !important;
  }
  .count-grid .icon i::before {
    font-size: 1.9rem !important;
  }
  .subtitleScreenshots {
    padding-bottom: 3rem;
  }
  .pricesTable {
    flex-wrap: wrap;
  }
  .price-table {
    margin-bottom: 3rem;
  }
  .phoneFrame-pricing-bottom {
    transform: scale(0.5);
    /* margin-top: -13em !important; */
  }
  .price-table.plan-popular {
    margin: 0 !important;
    margin-bottom: 3rem !important;
  }
  .contact-info.res-margin {
    width: 303px !important;
  }
  .priceAnimateContainer .wpb_single_image.mobile-phone.is-inViewport {
    margin-top: -9rem !important;
  }
  .priceAnimateContainer .wpb_single_image .vc_figure {
    max-height: 35vh !important;
  }
  section#contact {
    margin-top: 0 !important;
  }
  #subscribe-form {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .buttonSubContent {
    width: 35% !important;
  }
  section#blog {
    max-height: 293vh !important;
  }
  .container-features .features-presentation:last-child {
    flex-direction: column-reverse;
  }
  .priceAnimateContainer {
    height: 26vh !important;
  }
  .phoneFrame-pricing-bottom #phone {
    margin-top: -8rem !important;
  }
  .animeFeaturesBlockContainer {
    width: 100% !important;
  }
  .wpb_wrapperpres {
    width: 100% !important;
  }
}

.logo-content img {
  animation: logoRotate 6s infinite linear;
}

@keyframes logoRotate {
  to {
    transform: rotateY(360deg);
  }
}
