.header-scroolIndicator {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background: black;
  color: white;
  left: 0;
}
.header-scroolIndicator h2 {
  text-align: center;
}
.progress-container {
  width: 100%;
  height: 8px;
  background: #ccc;
}
.progress-bar {
  height: 8px;
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  width: 0%;
  transition: width 0.2s ease-out;
}
