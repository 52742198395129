.containerGame {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

#score {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 2em;
  font-weight: 900;
  z-index: 10;
}

#introduction {
  width: 200px;
  height: 150px;
  position: absolute;
  font-weight: 600;
  font-size: 0.8em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  transition: opacity 2s;
}

#restart {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  color: white;
  background-color: red;
  border: none;
  font-weight: 700;
  font-size: 1.2em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: none;
  cursor: pointer;
}
#introduction,
#restart {
  top: 44%;
  left: 50%;
}

#perfect {
  position: absolute;
  opacity: 0;
  transition: opacity 2s;
}
