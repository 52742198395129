#lightcase-overlay {
  display: none;
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 2000;
  top: -9999px;
  bottom: -9999px;
  left: 0;
  background: #000;
}
#lightcase-loading,
#lightcase-loading:focus {
  text-decoration: none;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
#lightcase-loading {
  position: fixed;
  z-index: 9999;
  width: 1.123 em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  opacity: 1;
  font-size: 32px;
  text-shadow: 0 0 15px #fff;
  transform-origin: 50% 53%;
  animation: lightcase-spin 0.5s infinite linear;
  -moz-animation: lightcase-spin 0.5s infinite linear;
  -o-animation: lightcase-spin 0.5s infinite linear;
  animation: lightcase-spin 0.5s infinite linear;
}
@keyframes lightcase-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
#lightcase-case {
  display: none;
  position: fixed;
  z-index: 2002;
  top: 33%;
  left: 50%;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  text-shadow: 0 0 10px rgb(0 0 0 / 50%);
}
@media screen and (min-width: 641px) {
  html:not([data-lc-type="error"]) #lightcase-content {
    position: relative;
    z-index: 1;
    text-shadow: none;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 30px rgb(0 0 0 / 50%);
    backface-visibility: hidden;
  }
}
[class*="lightcase-icon-"].lightcase-icon-close {
  position: fixed;
  top: 15px;
  right: 15px;
  bottom: auto;
  margin: 0;
  opacity: 0;
  outline: none;
}
@media screen and (min-width: 641px) {
  a[class*="lightcase-icon-"] {
    bottom: 50%;
    margin-bottom: -0.5em;
  }
}
a[class*="lightcase-icon-"],
a[class*="lightcase-icon-"]:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
a[class*="lightcase-icon-"] {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  outline: none;
  cursor: pointer;
}
.lightcase-icon-close {
  border: 1px solid white;
  background: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lightcase-icon-close p {
  color: white;
  font-size: 18px;
}
