.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 40px 20px;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* @media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
} */
/* @media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
} */
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.main-menu-area .navbar-brand1 {
  display: block;
}
.main-menu-area.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 6%);
  height: 5.6em;
}
@media (min-width: 992px) {
  .main-menu-area.fixed-menu {
    padding: 17px 0;
  }
}
@media (min-width: 992px) {
  .main-menu-area.fixed-menu .nav-menu li a {
    color: #111;
  }
}
@media (min-width: 1200px) {
  .nav-menu li a {
    padding: 10px 20px;
  }
}
.main-menu-area.fixed-menu .navbar-brand2 {
  display: flex;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.main-menu-area .navbar-brand2 {
  display: none;
}
.menu-bar {
  position: relative;
  width: 30px;
  height: 40px;
  border-radius: 3px;
}
.menu-bar span {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 3px;
  margin-top: -1px;
  margin-left: -15px;
  background-color: #fff;
}
.menu-bar span:nth-child(1) {
  margin-top: -9px;
}
.menu-bar span:nth-child(3) {
  margin-top: 7px;
}
.header.mobile-menu-open {
  z-index: 999999;
}

@media (max-width: 991px) {
  .mobile-menu-open .op-mobile-menu {
    left: 0;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .m-menu-header {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .close-button {
    position: relative;
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .close-button:before {
    transform: rotate(45deg);
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .close-button:before,
  .op-mobile-menu .close-button:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 10px;
    width: 20px;
    height: 2px;
    margin-top: -1px;
    background-color: #999;
    transform-origin: center;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .close-button:after {
    transform: rotate(-45deg);
  }
}
.nav-menu {
  margin: 0;
  padding: 0;
}
.nav-menu li a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
}

.nav-menu li a:active,
.nav-menu li a:hover,
.nav-menu li a::before {
  border-bottom: 1px solid #7c4fe0;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
@media (max-width: 991px) {
  .op-mobile-menu .nav-menu li {
    padding: 0 30px;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .nav-menu > li:first-child > a {
    padding-top: 0;
  }
}
@media (max-width: 991px) {
  .op-mobile-menu .nav-menu li a {
    clear: both;
    padding: 10px 0;
    border: 0;
    color: #111;
  }
}
.nav-menu li a span {
  position: relative;
}
.nav-menu li a span:after {
  position: absolute;
  content: "";
  left: auto;
  right: 0;
  bottom: -2px;
  width: 0%;
  height: 1px;
  background-color: #fff;
  opacity: 0.4;
  transition: all 0.3s;
}
/* .op-mobile-menu.col-lg-10.p-0.d-lg-flex.align-items-center.justify-content-end {
  display: none;
} */
.header.mobile-menu-open {
  z-index: 999999;
}
.logoMobile {
  display: flex;
}
#menu-main-menu {
  text-align: left;
}
.main-menu-area.fixed-menu .menu-bar span {
  background-color: #111;
}
@media (min-width: 992px) {
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}
.nav-menu {
  margin: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.logo-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textLogo,
.nav-menu li a {
  font-family: "Permanent Marker";
  font-style: normal;
  font-weight: 400;
}
.fb-like.fb_iframe_widget {
  margin-right: 2rem;
  transform: translate(0, 5px);
}
@media screen and (max-width: 900px) {
  .fb-like.fb_iframe_widget {
    margin-right: 0rem;
    transform: translate(0, 0);
  }
}
