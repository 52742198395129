.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homePage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}
/*ONLOAD PAGE ANIMATIONS LOGIC */
[data-inviewport="fadeInUp"].is-inViewport {
  animation: 1s fadeInUp 0.2s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="fadeInUp"]:not(.is-inViewport) {
  transition: 1s;
  opacity: 0;
}
[data-inviewport="scaleUp"].is-inViewport {
  animation: scaleUp 1s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="scaleUp"]:not(.is-inViewport) {
  transition: 1s;
  opacity: 0;
}

[data-inviewport="fadeInUpMediumDelay"]:not(.is-inViewport) {
  opacity: 0;
  transition: 1s;
}
[data-inviewport="fadeInUpMediumDelay"].is-inViewport {
  animation: 1s fadeInUp 0.5s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
[data-inviewport="fadeInUpLargeDelay"]:not(.is-inViewport) {
  opacity: 0;
  transition: 1s;
}
[data-inviewport="fadeInUpLargeDelay"].is-inViewport {
  animation: 1s fadeInUp 0.9s ease-in-out both;
  opacity: 1;
  transition: 1s;
}
