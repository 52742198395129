.errorComponent {
  position: absolute;
  top: 150px;
  transform: scale(2.5) translate(21px, -1px);
}

#handboy {
  animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 98% 98%;
  transform-box: fill-box;
}

#girllight {
  animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 0% 97%;
  transform-box: fill-box;
}

#hairgirl {
  animation: swinghair ease-in-out 1.3s infinite alternate;
  transform-origin: 60% 0%;
  transform-box: fill-box;
}

#zero {
  transform-origin: bottom;
  transform-box: fill-box;
}

/*************swing************/
@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/*************swing hair************/
@keyframes swinghair {
  0% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-6deg);
  }
}

@media screen and (max-width: 900px) {
  .containerError {
    transform: scale(0.5) translate(-43px, 10px);
  }
}
