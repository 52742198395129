.mb-0 img:hover {
  background-color: rgba(124, 79, 224, 0.85);
}
.team-member .team-social {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  border-radius: 6px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  background: rgb(124 79 224 / 94%);
  width: 288px;
}
.team-image:hover {
  transform: translateY(-4px);
  transition: all 0.7s ease-in-out;
}
.team-image:hover .team-social {
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}
.team-member {
  position: relative;
  background-color: #fff;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}
.team-member .team-image {
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  width: 288px;
  box-shadow: 0 1px 5px black;
  transition: all 0.7s ease-in-out;
}
.team-member .team-social-inner {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.team-member .team-social-inner > a {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  margin: 0 12px;
  background: #fff;
  color: #7c4fe0f0;
  transition: all 0.3s;
  padding: 7px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.team-member .team-social-inner > a i {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-member .team-social-inner > a:hover i {
  transform: scale(1.5);
  transition: all 0.7s ease-in-out;
}
.team-member .team-details {
  position: relative;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .team-member .team-image img {
  width: 100%;
  transition: all 0.3s;
} */
.vc_row.wpb_row.vc_row-fluid.teamPictures {
  width: 84%;
  height: auto;
  display: flex;
}
.team-member .team-details .title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  transition: all 0.3s;
  color: #111 !important;
}
.mb-0 img {
  border-radius: 7px;
}
.titleTextContent {
  width: 100%;
  display: contents;
}
#team {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pictMe {
  border-radius: 7px;
  width: 288px;
  height: auto;
}
.team-details p {
  max-width: 45%;
}
