.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.contact-info-container {
  width: 33%;
}
.contact-info.res-margin {
  text-align: left;
}
.form-container {
  width: 47%;
}
.wpcf7-form {
  padding: 20px;
  background-color: #fafbfe;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  margin-bottom: 2rem;
}
.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}
.first-line {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 2rem;
}
.contact-col-first {
  width: 43%;
}
.subject {
  margin-bottom: 2rem;
}
textarea {
  overflow: auto;
  resize: vertical;
  line-height: 1.75 !important;
  padding: 15px 20px !important;
  height: auto !important;
}
.checkout-button,
.wp-block-button__link,
.wp-block-search__button,
input.btn,
input.search-submit {
  background-color: #7c4fe0 !important;
}
.buttonForm {
  width: 96%;
  margin-top: 2rem;
  margin-left: 1rem;
}
.headContact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headContact .section-title,
.container-pricing .section-title,
.container-blog .section-title {
  width: 30%;
}
.subscribesection-title p,
.container-support .section-title p,
.subtitleScreenshots,
.subtitleTesti,
.subtitleFeatures {
  width: 60%;
}
.messageResult-ok,
.messageResult-error {
  font-weight: bold;
}
.messageResult-ok {
  color: green;
}
.messageResult-error {
  color: red;
}
