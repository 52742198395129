section#clients {
  height: 30vh;
}

.mi-slider {
  position: relative;
  height: 476px;
  transform: scale(0.62) translate(0, -21rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mi-slider ul {
  list-style-type: none;
  position: absolute;
  width: 90%;
  /* left: 3%; */
  bottom: -121px;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
  padding-left: 0;
}
.mi-slider ul.mi-current {
  pointer-events: auto;
}
.no-js .mi-slider ul {
  position: relative;
  left: auto;
  bottom: auto;
  margin: 0;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mi-slider ul li {
  display: inline-block;
  padding: 20px;
  width: 38%;
  max-width: 300px;
  transform: translateX(600%);
  transition: opacity 0.2s linear;
}
.no-js .mi-slider ul li {
  transform: translateX(0);
}
.mi-slider ul li a,
.mi-slider ul li img {
  display: block;
  margin: 0 auto;
}

.mi-slider ul li a {
  outline: none;
  cursor: pointer;
}
#ListLevel ul li,
#ListBadge ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.mi-slider ul li img {
  max-width: 100%;

  border: none;
}
#ListLevel {
  width: 100%;
}

.mi-slider ul li h4 {
  display: inline-block;
  font-family: Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond,
    "Times New Roman", serif;
  font-style: italic;
  font-weight: 400;
  font-size: 24px !important;
  width: 270px;
  margin-bottom: 3.2rem;
}
.mi-slider ul li:hover {
  opacity: 0.7;
}
.mi-slider nav {
  position: absolute;
  top: 34rem;
  text-align: center;
  max-width: 89%;
  margin: 0 auto;
  border-top: 5px solid #333;
}
.no-js nav {
  display: none;
}
.mi-slider nav span {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 40px 30px 30px 34px;
  position: relative;
  color: #888;
  outline: none;
  transition: color 0.2s linear;
  cursor: pointer;
  transition: all 0.7s ease-in-out;
}

.mi-slider nav span:hover,
.mi-slider nav span.mi-selected {
  color: #000;
  transition: all 0.7s ease-in-out;
}
.mi-slider nav span.mi-selected:after,
.mi-slider nav span.mi-selected:before {
  content: "";
  position: absolute;
  top: -5px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  transition: all 0.7s ease-in-out;
}

.mi-slider nav span.mi-selected:after {
  border-color: transparent;
  border-top-color: #fbfbfe;
  border-width: 20px;
  left: 50%;
  margin-left: -20px;
  top: -6px;
}

.mi-slider nav span.mi-selected:before {
  border-color: transparent;
  border-top-color: #333;
  border-width: 27px;
  left: 50%;
  margin-left: -27px;
  transition: all 0.7s ease-in-out;
}
.mi-slider ul:first-child li,
.no-js .mi-slider ul li {
  animation: scaleUp 350ms ease-in-out both;
}

@keyframes scaleUp {
  0% {
    transform: translateX(0) scale(0);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
.mi-slider ul:first-child li:first-child {
  animation-delay: 90ms;
}

.mi-slider ul:first-child li:nth-child(2) {
  animation-delay: 180ms;
}

.mi-slider ul:first-child li:nth-child(3) {
  animation-delay: 270ms;
}

.mi-slider ul:first-child li:nth-child(4) {
  animation-delay: 360ms;
}
/* moveFromRight */

.mi-slider ul.mi-moveFromRight li {
  animation: moveFromRight 350ms ease-in-out both;
}

/* moveFromLeft */

.mi-slider ul.mi-moveFromLeft li {
  animation: moveFromLeft 350ms ease-in-out both;
}

/* moveToRight */

.mi-slider ul.mi-moveToRight li {
  animation: moveToRight 350ms ease-in-out both;
}

/* moveToLeft */

.mi-slider ul.mi-moveToLeft li {
  animation: moveToLeft 350ms ease-in-out both;
}
.mi-slider ul.mi-moveToLeft li:first-child,
.mi-slider ul.mi-moveFromRight li:first-child,
.mi-slider ul.mi-moveToRight li:nth-child(4),
.mi-slider ul.mi-moveFromLeft li:nth-child(4) {
  animation-delay: 0ms;
}
.mi-slider ul.mi-moveToLeft li:nth-child(2),
.mi-slider ul.mi-moveFromRight li:nth-child(2),
.mi-slider ul.mi-moveToRight li:nth-child(3),
.mi-slider ul.mi-moveFromLeft li:nth-child(3) {
  -webkit-animation-delay: 90ms;
  animation-delay: 90ms;
}

.mi-slider ul.mi-moveToLeft li:nth-child(3),
.mi-slider ul.mi-moveFromRight li:nth-child(3),
.mi-slider ul.mi-moveToRight li:nth-child(2),
.mi-slider ul.mi-moveFromLeft li:nth-child(2) {
  -webkit-animation-delay: 180ms;
  animation-delay: 180ms;
}

.mi-slider ul.mi-moveToLeft li:nth-child(4),
.mi-slider ul.mi-moveFromRight li:nth-child(4),
.mi-slider ul.mi-moveToRight li:first-child,
.mi-slider ul.mi-moveFromLeft li:first-child {
  -webkit-animation-delay: 270ms;
  animation-delay: 270ms;
}
@keyframes moveFromRight {
  0% {
    transform: translateX(600%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveFromLeft {
  0% {
    transform: translateX(-600%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(600%);
  }
}

@keyframes moveToLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-600%);
  }
}
@media screen and (max-width: 910px) {
  .mi-slider nav {
    max-width: 100%;
  }

  .mi-slider nav span {
    font-size: 10px;
    padding: 40px 10px 30px 14px;
  }
}
@media screen and (max-width: 740px) {
  .mi-slider {
    height: 300px;
  }

  .mi-slider nav {
    top: 220px;
  }
}
@media screen and (max-width: 490px) {
  .mi-slider {
    text-align: center;
    height: auto;
  }

  .mi-slider ul {
    position: relative;
    display: inline;
    bottom: auto;
    pointer-events: auto;
  }

  .mi-slider ul li {
    animation: none !important;
    transform: translateX(0) !important;
    padding: 10px 3px;
    min-width: 140px;
  }

  /* .mi-slider nav {
    display: none;
  } */
}
