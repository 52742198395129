.socialMedia {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sci {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sci li {
  list-style: none;
}
.sci li a {
  position: relative;
  font-size: 1.4rem;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  border-radius: 10px;
  text-decoration: none;
}
.sci li a:hover {
  font-size: 2rem;
}
