.containerCard {
  position: relative;
  /* width: 1280px; */
  display: flex;
  align-items: center;
}
.containerCard .card {
  position: relative;
  width: 300px;
  height: 400px;
  background: linear-gradient(#7b16ff, #7b16ff 30%, #40c 30%);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 3px 16px darkgrey;
}
.imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 100000;
  transform-origin: top;
  border-radius: 20px;
  overflow: hidden;
  overflow: hidden;
  box-shadow: 0 3px 16px darkgrey;
}
.containerCard .card:hover .imgBx {
  transform: translateY(30px) scale(0.5);
}
.imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 30px;
  transform: translateY(100%);
  transition: 0.5s;
}
.card:hover .cardContent {
  transform: translateY(0);
}
.cardDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transform: translate(0px, 32px);
  width: 80%;
}
.cardDetails h2 {
  color: #fff;
  font-size: 1.2em;
  font-weight: 500;
}
.cardDetails h2 span {
  font-size: 0.6em;
  font-weight: 400;
  color: #fff;
}
.social_icons {
  position: relative;
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  transform: translate(-20px, -7px);
}
.social_icons li {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.social_icons li a {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7b16ff;
  border-radius: 50%;
  font-size: 1.5em;
  color: white;
}
.social_icons li a:hover {
  transform: rotate(360deg);
}
